export const regionesNombre = {
  1: "Tarapacá",
  2: "Antofagasta",
  3: "Atacama",
  4: "Coquimbo",
  5: "Valparaíso",
  6: "Libertador Gral. Bernardo O'Higgins",
  7: "Maule",
  8: "Biobio",
  9: "Araucanía",
  10: "Los Lagos",
  11: "Aisén del Gral. Carlos Ibáñez del Campo",
  12: "Magallanes y de la Antártica Chilena",
  13: "Metropolitana de Santiago",
  14: "Los Ríos",
  15: "Arica y Parinacota",
  16: "Ñuble",
};

export const regionsData = [
  { quantity: 0, name: "Tarapacá", amount: 0, number: 0, romanNumeral: "I" },
  {
    quantity: 0,
    name: "Antofagasta",
    amount: 0,
    number: 1,
    romanNumeral: "II",
  },
  { quantity: 0, name: "Atacama", amount: 0, number: 2, romanNumeral: "III" },
  { quantity: 0, name: "Coquimbo", amount: 0, number: 3, romanNumeral: "IV" },
  { quantity: 0, name: "Valparaíso", amount: 0, number: 4, romanNumeral: "V" },
  {
    quantity: 0,
    name: "Libertador Gral. Bernardo O'Higgins",
    amount: 0,
    number: 5,
    romanNumeral: "VI",
  },
  { quantity: 0, name: "Maule", amount: 0, number: 6, romanNumeral: "VII" },
  { quantity: 0, name: "Biobio", amount: 0, number: 7, romanNumeral: "VIII" },
  { quantity: 0, name: "Araucanía", amount: 0, number: 8, romanNumeral: "IX" },
  { quantity: 0, name: "Los Lagos", amount: 0, number: 9, romanNumeral: "X" },
  {
    quantity: 0,
    name: "Aisén del Gral. Carlos Ibáñez del Campo",
    amount: 0,
    number: 10,
    romanNumeral: "XI",
  },
  {
    quantity: 0,
    name: "Magallanes y de la Antártica Chilena",
    amount: 0,
    number: 11,
    romanNumeral: "XII",
  },
  {
    quantity: 0,
    name: "Metropolitana de Santiago",
    amount: 0,
    number: 12,
    romanNumeral: "RM",
  },
  { quantity: 0, name: "Los Ríos", amount: 0, number: 13, romanNumeral: "XIV" },
  {
    quantity: 0,
    name: "Arica y Parinacota",
    amount: 0,
    number: 14,
    romanNumeral: "XV",
  },
  { quantity: 0, name: "Ñuble", amount: 0, number: 15, romanNumeral: "XVI" },
];

export const regionesRomanos = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
  9: "IX",
  10: "X",
  11: "XI",
  12: "XII",
  13: "RM",
  14: "XIV",
  15: "XV",
  16: "XVI",
};
