import React from 'react';
import './style.scss';

const Footer = () => {
    return <div className="footer">
            <div className="footer-left">
                <p>Somos el Centro Tecnológico para la Innovación en la Construcción, y nuestro objetivo es contribuir al proceso de
                transformación de la industria de la construcción.
                </p>
            </div>
            <div className="footer-right">
                <div className="logo" style={{'background': `url(${window.PUBLIC_URL}/assets/img/logo_corfo.svg) no-repeat`}}></div>
            </div>
        </div>;
}

export default Footer;