import { Box, LinearProgress } from "@material-ui/core";
import React from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from "recharts";

const COLORS = [
  "#0096af",
  "#3ea0b7",
  "#5fa9bf",
  "#678893",
  "#5a9ea5",
  "#a4c8d7",
  // "#e4f6c5",
  // "#5fa9bf",
];

// const tooltipFormatter = (value) => {
//   return [` ${value}`, "Cantidad"];
// };

// const tooltipLabelFormatter = (value) => {
//   return `Categoría: ${value}`;
//   // : ${
//   //   regionesNombre[
//   //     Object.keys(regionesRomanos).find((key) => regionesRomanos[key] === value)
//   //   ]
//   // }
// };

const PieChartCard = (props) => {
  const { title, data, loading, total, colors=COLORS } = props;

  const sordByQuantity = (itemA, itemB) =>
    itemA.quantity > itemB.quantity
      ? -1
      : itemB.quantity > itemA.quantity
      ? 1
      : 0;

  const filterEmpty = (item) => item.quantity > 0;

  const groupLowers = (items) => {
    return items.reduce((acc, curr) => {
      if (curr.quantity < total * 0.03 || curr.category == "Otros") {
        if (!acc.find((item) => item.category == "Otros")) {
          acc.push(curr);
        } else {
          const index = acc.findIndex((item) => item.category == "Otros");
          acc[index].quantity += curr.quantity;
        }
      } else {
        if (!acc.find((item) => item.categoryCode == curr.categoryCode)) {
          acc.push(curr);
        }
      }
      return acc;
    }, []);
  };

  const CustomTooltip = ({ payload }) => {
    return (
      <Box className="piechart-tooltip-container">
        <b>{payload?.[0]?.payload?.category}</b>
        <span>
          <p className="desc">
            Cantidad: <b>{payload?.[0]?.payload?.quantity}</b>
          </p>
        </span>
      </Box>
    );
  };

  return (
    <div className="row bar-chart-container">
      <div className="col-12 col-md-5">
        <h5 className="card-header__title plot-title text-white">{title}</h5>
        <p className="d-block d-sm-none chart-help-text">
          Has click sobre el gráfico para ver el nombre de las categorías
        </p>
      </div>
      <div className="col-12 col-md-7 align-center" style={{ height: 350 }}>
        {loading ? (
          <div className="loader-chart-container">
            {/* <LinearProgress className="linear-loader" /> */}
            <p>Obteniendo información...</p>
          </div>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={350}>
              <PieChart>
                <Pie
                  dataKey="quantity"
                  data={groupLowers(
                    data.sort(sordByQuantity).filter(filterEmpty)
                  )}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={(item) => item.category}
                >
                  {data.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        colors[
                          Math.floor(Math.random() * colors.length) %
                            colors.length
                        ]
                      }
                    />
                  ))}
                </Pie>
                <Tooltip
                  content={<CustomTooltip />}
                />
              </PieChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  );
};

export default PieChartCard;
