
//Guarda el mapa de chile. Los indices del arreglo son sensibles a la información que provienen de las licitaciones por región 
const MapData = [
	{
		id: 'CL-TA',
		d: `M26.1,62.7L26,60.4l-1.4-2.2L22.9,56l0-3.9l1.6-2.2l-0.3-2.2l-1.7-0.3L21.1,46l0-2.1l0,0l0.4,0.2l1.7-0.9
		l1.1-1.7l0.5-0.6l1.3-1.2l0.2-0.2l0.2-0.5l0.1-0.4l0.2-0.4l0.7-0.9l1-0.7l1.6,1.5l0.4,0.3l0.6,0.4l1.5,1.2l0.6,0l0.6-0.9l0.7-0.8
		l1.2-0.6l2.1,0.7l0,0.5l0.1,0.6l0.5,1.8l0.4,0.1l0.3-0.3l1.5-0.7l1.5-0.3l0.2,0.4l0.3,0.3l0.8,0l0.4-0.2l0.3-0.2l0.5-0.5l0.3-0.4
		l0.2-0.5l0.2-0.4l0.1-0.4l0.1-0.3l0.6-0.8l0.4-0.4l0.3,0.1l0.3,0.5l0.2,0.3l0.7,0.5l2.1,0l0.3-0.1l0,0l0,0l-0.1,1.1l-0.3,2.6
		l0.2,1.2l0.1,0.3l0.2,0.3l0.4,0.1l0.3-0.2l0.2-0.4l0.4-1.1l0-0.4l0-0.4l-0.2-0.8l0.1-0.4l0.4-0.2l0.7,0.1l0.4,0.1l1.1,0.6l0.4,0.2
		l0.4,0.2l0.4,0.1l0.5,0l0.6,1.3l0.2,1.8l1.2,3.8l1.1,4.5l0.3,1.1l-0.3,1l-0.1,0.9l-0.2,1.6l0,0.4l0.1,1.5l0.1,0.2l0,0L58,59.5
		l-0.5,0.1L57,59.3l-3,0.8l-0.8,0.3l-0.6,0.3l-0.8-0.2l-0.5-0.3l-0.8,0.1l-0.4,0.2l-0.2,0.2l-0.5,0.5l-6.2-0.5l-1.4-0.4l-0.4-0.1
		l-0.9,0.4l-1.1-0.1L39,60.5l-0.4-0.1l-1.2,0l-0.4,0l-1,0.2l-4,0.6l-2.3,0.5l-1.8,0.5l-1.1,0.6L26.1,62.7z`,
        cx: 45,
        cy: 53
	},
	{
		id: 'CL-AN',
		d: `M51.2,37.1l0.1-0.4l0-0.4l0-0.4l4.4-2.8l1-0.6l4.7-0.1l0.3-0.2l0.6-0.4l0.5-0.2l0.7-0.2l1.1-0.2l0.3,0l0.6,0
		l0.5,0l0.7,0l1.3-1.3l0.2-0.3l0.7-0.4l2-0.3l0.5,0l0.4,0.1l1.9-0.6l2-0.6l2.4,0.5l1.8-0.1l0.7-1.2l0.3-3.1l-0.8-3.5L79.7,18l1.2-1.1
		l1.6-1.5l1.9,0.6l1.8,0.6l1.8,0.6l0.5,0.2l9.8,2.5l2,4.3l1.7,3.7l1.9,4.1l0.4,0.9l0.8,0.3l0.8,0.6l-0.1,0.7l0.2,0.3l1.6,1l1,0.6
		l0.9,0.5l0.8,0.4l0.5,0l0.9-0.3l0.4-0.7l3.5-2.1l0.5,0.9l0.2,0.9l0.1,0.1l0.4,0.3l1.1,0.3l0,0l0,0.8l0.2,0.6l0.3,0.4l0.5,0.6
		l0.5,0.5l0.3,0.3l0.1,0.3l0,0.7l-0.1,0.6l0.1,0.5l0.2,0.5l0.2,0.4l0.2,0.3l0.6,0.7l0.8,0.3l1.1,0.1l0.6,0.3l0,0.3l2.8-0.2l0,4.2
		l1.7,0.5l0.3,1.4l1.7,1.4l-1.6,2.2l-1.5,1.1l0,0.2l-0.1,1l-0.1,0.4l0,0.5l0.2,0.5l0.2,0.3l0.3,0.5l0.3,0.3l1.1,1.8l0.1,1l0.2,1.3
		l0.1,0.6l0.1,0.4l0.1,0.4l0.1,0.3l0.2,0.3l0.6,0.8l0.9,0.7l0,0l-0.7-0.4l-0.3-0.1l-0.5,0.1l-0.9,0.3l-0.4,0.4l-0.3,0.3l-1.1,0.5
		l-1.7-0.4l-0.7-0.2l-1.5-0.7l-1.1-0.8l-0.4-0.5l-1.7-1.2l-1.7-0.2l-0.9,0l-0.9,0.3l-0.9,0.7l-2.1-0.1l-0.9,0.5l-0.8,0.3l-2,0.5
		l-2.5-0.2l-2.5-0.3l-0.8-0.1l-0.3,0.2l-1.3-0.2l-0.8-0.2l-0.3-0.2l-1.1-0.1L99.2,65l-0.5,0.2l-0.5,0l-1.9-0.1L95.1,65l-0.6-0.4
		l-0.3-0.4L93.8,64l-0.7-0.3l-0.4-0.1l-0.4-0.1l-0.6,0l-0.5,0.1l-0.5,0.2L90.5,64l-0.3,0.5l-0.2,0.4l0.1,0.3l0.9,0.4l-0.2,1.2
		l-1.9-0.3l-2.3,0l-2.5-0.3l-0.6-0.5l-0.2-0.5l0.7-0.1l0.4-0.3l-0.2-0.8l-0.2-0.5l-0.3-0.3L83.3,63l-0.3-0.2l-1-0.4l-0.9-0.3
		l-0.4,0.1l-0.3,0.1l-0.7,0.1l-2.2-0.4l-3-0.3l-2.1-0.2l-2.3-0.3l-1-0.2L68,60.8l-3.9-0.5l-0.5,0.1l-1,0l-0.8-0.2l-0.6-0.6l-0.8-0.1
		L58.9,59l0,0l-0.1-0.2l-0.1-1.5l0-0.4l0.2-1.6l0.1-0.9l0.3-1l-0.3-1.1L58,47.8L56.8,44l-0.2-1.8L56,40.9l-0.5,0l-0.4-0.1l-0.4-0.2
		l-0.4-0.2l-1.1-0.6l-0.4-0.1l-0.7-0.1l-0.4,0.2l-0.1,0.4l0.2,0.8l0,0.4l0,0.4l-0.4,1.1l-0.2,0.4L51,43.5l-0.4-0.1l-0.2-0.3l-0.1-0.3
		l-0.2-1.2l0.3-2.6l0.1-1.1l0,0l0.2-0.1l0.3-0.3L51.2,37.1z`,
        cx: 93,
        cy: 47
	},
	{
		id: 'CL-AT',
		d: `M119.1,37.3l1.2,0.3l0.3,0.1l0.5,0l0.4-0.1l1.9-0.6l1.6-0.8l0.4-0.1l2.5-0.6l4-0.8l0.5,0.3l0.8,0.9l0.4,0.6
		l0.3,0.4l0.4,0.3l0.7,0.2l0.5,0l0.9,0l0.3,0l0.8,0l0.3-0.1l0.3-0.1l0.6-0.4l1.8-1.5l3.1-2l0.3-0.1l1.8,0.5l0.3,0.4l0.3,0.5l0.1,0.3
		l1.2,3.3l-0.5,0.7l-0.1,0.6l0,0.6l0.2,0.4l0.9,0.6l0.5,0.3l0.5,0.2l0.6-0.1l1.4,0.5l3.8,2l0.5,0.4l1.6,0.6l0.7,0.1l2.1,0.5l0.4,0.1
		l0.4,0.2l0.3,0.2l0.1,0.4l0.1,0.5l0.3,0.5l1,0.4l0.7,0.2l0.4,0.2l0.9,0.7l2.2,1.9l0.9,1l0.5,0.7l2.3,0.1l0.6,0.1l0.3,0.4l1.3,0.6
		l0.8,0l0.4-0.1l0.4-0.1l0.5-0.1l1.4,0.5l2.1,0.4l0.9,0l0.4,0.1l0.3,0.2l0.3,0.5l0.1,0.9l1,0.8l1.5,1l1,0.1l0.4-0.1l0.8-0.4l1.8-0.6
		l0.2-0.1l0,0l-0.1,1.7l-1.1,2.5l-0.5,1.1l-0.5,2l-0.4-0.1l-0.6,0l-0.7,0.2l-0.3,0.2l-2.2,1.9l-0.3,0.3l-0.4,0.4l-0.3,0.5l-0.2,0.4
		l-0.1,0.5l0.9,1.1l1.1,0.5l2.8,1.1l0.3,2.2l-1.7,1.1l-2,1L180,75l0.1,0.3l0.4,1.7l0.1,0.5l0,0.5l0,0l-1.2-0.2l-2.1,0.4l-1.3,0.1
		l-0.4-0.1l-0.6-0.5l-1.3-1.3l-1.4-1.1l-0.9-0.1l-1-0.1l-0.7-0.3l-0.6-0.4l-2-0.9l-1.8-0.1l-1.3,0l-3.1-0.2l-1.9-0.5l-0.9-0.3l-1-0.5
		l-0.7-0.5l-0.3-0.6l-0.6-0.7l-2.2-0.3l-0.8,0.2l-1.6,0.4l-2.5,0.5l-0.4-0.1l-0.4-0.2l-0.3-0.3l-0.2-0.8l-1-0.7l-0.8-0.4l-1,0.2
		l-0.5,0.2l-0.4,0.1l-4.3-1.7l-0.5-0.1l-2-0.1l-2.1-0.6l-1.6,0.4l-0.5,0l-1.3-0.1l0,0l-0.9-0.7l-0.6-0.8l-0.2-0.3l-0.1-0.3l-0.1-0.4
		l-0.1-0.4l-0.1-0.6l-0.2-1.3l-0.1-1l-1.1-1.8l-0.3-0.3l-0.3-0.5l-0.2-0.3l-0.2-0.5l0-0.5l0.1-0.4l0.1-1l0-0.2l1.5-1.1l1.6-2.2
		l-1.7-1.4l-0.3-1.4l-1.7-0.5l0-4.2l-2.8,0.2l0-0.3l-0.6-0.3l-1.1-0.1l-0.8-0.3l-0.6-0.7l-0.2-0.3l-0.2-0.4l-0.2-0.5l-0.1-0.5
		l0.1-0.6l0-0.7l-0.1-0.3l-0.3-0.3l-0.5-0.5l-0.5-0.6l-0.3-0.4l-0.2-0.6l0-0.8l0,0l0.1,0.1l0.6,0.3L119.1,37.3z`,
        cx: 160,
        cy: 64
	},
	{
		id: 'CL-CO',
		d: `M180.7,78.1l0-0.5l-0.1-0.5l-0.4-1.7L180,75l-0.1-0.2l2-1l1.7-1.1l-0.3-2.2l-2.8-1.1l-1.1-0.5l-0.9-1.1l0.1-0.5
		l0.2-0.4l0.3-0.5l0.4-0.4l0.3-0.3l2.2-1.9l0.3-0.2l0.7-0.2l0.6,0l0.4,0.1l0.5-2l0.5-1.1l1.1-2.5l0.1-1.7l0,0l0.1,0l0.7,0l0.5,0.1
		l0.4,0.1l0.4-0.1l0.3-0.2l0.3-0.5l0.1,0l1.3-0.2l1.9,0l0.9,0.1l0.4,0.1l1.7-0.9l0.3-0.3l0.4-0.2l0.8,0l2.2,0.9l0.8,0.6l0.4,0.8
		l0.1,0.4l0,0.3l0.8,1.2l2,1.1l1.3,0.6l0.7,0.2l2.4,0.6l1.1,0.1l0.7,0.1l0.4-0.1l0.6-0.5l0.6,0l1.1,0.9l0.4,1l-0.3,0.3l1.1,1l0.4,0.1
		l2.3,0.4l2.4,0.1l1,0.1l0.6,0.1l4.4-1.9l0.3-0.2l0.3-0.2l0.2-0.3l0.1-0.6l-0.1-1l0.2-0.3l0.8-0.7l0.4-0.1l1.3,0.4l0.1,0.6l0.7,0.6
		l2.4-0.5l0.6,0.1l0,0l0.2,1.3l-0.2,0.4l-0.4,0.1l-0.8,0.2l-0.3,0.4l-0.1,0.4l0,0.3l0,0.4l-0.1,0.7l-0.5,0.7l-0.4,0l-0.5,0.1l-0.6,1
		l0.2,4.3l0.7,0.4l0.8,0.7l0.2,0.3l0.7,3l0,0.6l0,0l-0.1,0.4l-0.3,0.7l0,0l0,0.4l0.2,0.3l0.3,0.3l0,0l-1.6-0.1l-0.8-0.1l-0.4-0.1
		l-0.7-0.1l-1.4,0l-2,0.1l-1,0.4l-1.7,0.4l-1,0l-0.4,0.1l-3.4,0.8l-2.2,0.6l-0.3,0.1l-0.5,0l-0.9-0.2l-1.5-0.1l-0.6,0.2l-0.4,0.2
		l-0.4,0.1l-2.4,0.3l-1.7,0l-1.6,0l-0.9,0l-2.7-0.2l-1.1-0.5l-0.2-0.4l0.3-0.2l0.2-0.5l0-0.3l-0.3-0.5l-1.5-1.5l-0.5-0.3l-3.3-0.6
		l-0.7-0.7l-1.2,0l-0.4,0.4l-1,0.3l-3.2,0l-1.2-0.2l-0.6-0.2l-0.4,0l-1.5,0.5l-0.3,0.2l-0.3,0.4l-0.5,0.8l-0.2,0.4l-0.2,0.3l-0.4,0.2
		L180.7,78.1z`,
        cx: 210,
        cy: 74
	},
	{
		id: 'CL-VS',
		d: `M230,76.1l0-0.6l-0.7-3l-0.2-0.3l-0.8-0.7l-0.7-0.4l-0.2-4.3l0.6-1l0.5-0.1l0.4,0l0.5-0.7l0.1-0.7l0-0.4l0-0.3
		l0.1-0.4l0.3-0.4l0.8-0.2l0.4-0.1l0.2-0.4l-0.2-1.3l0,0l0.3-0.1l0.4-1l0.5-0.2l2.2-1.1l0.5-0.1l0.6,0.1l0.6-0.6l0.9-0.9l1.6-1.3
		l1.4-0.1l1,0.6l1.2,0.4l1,0.7l0.8,0.4l0,0l0.4,0.7l1.1,1.2l-0.6,1.5l-1.1,1l0,0.3l0.8,0.9l0,0.3l-0.7,0.5l-0.7,0.2l-0.6,0.6
		l-0.3,0.7l-0.3,1.3l0,2l0.6,1.6l0.2,0.3l0.6,0.1l0.8-0.2l2.1-0.1l0.7,1l0,0.9l0.2,0.9l0.4,0.2l1.2,0l0.4,0.1l0.6,0.4l1,1l0.2,0.4
		l0.3,0l1.2-0.2l1.3-0.6l2.3,0.6l0.8,0.7l0,0.5l-0.2,0.7l0,0.8l0,0.3l1.6,0.4l0,0l0.9,1.3l0,0l0.6,0.5l-0.1,0.5l-0.1,0.5l-0.3,0.4
		l0,0.7l0,0l-1.6-0.4l-1.2-0.5l-0.1-0.4l-0.1-0.5l-0.2-0.4l-0.4-0.5l-0.7-0.3l-0.3-0.1l-0.5-0.1l-1.3-0.1l-3.1,0.8l-2,0.2l-2.5,0.7
		l-0.3-0.1l0-0.7l-1.4-1.8l-1.9-0.6l-2.3-0.3l-1.2-0.6l-0.5-0.2l-0.5-0.1l-2.4,0l-1.6-0.5l-1.5,0.9l-0.9,0.6l-1,0.4l0,0l-0.4-0.3
		l-0.2-0.3l0-0.4l0,0l0.3-0.7L230,76.1L230,76.1z`,
        cx: 240,
        cy: 70
	},
	{
		id: 'CL-LI',
		d: `M259.1,79.4l0.6-1.4l-0.2-2.3l1.6-0.9l0.7-1.8l1.7-1.2l0,0l-0.1-1.4l-0.3-1.2l-0.7,0.1l-0.4-0.1l-2.2-1.8
		l-0.7-1.2l0.1-0.4l-0.1-1l-0.4-0.6l-0.7-1.7l0-0.5l0.2-0.5l0.3-0.3l1.8-0.6l0.9,0.1l0.3-1l-0.1-0.3l-0.3-0.4l0-0.4l0.2-0.3l0.7-0.2
		l1.1,0l0.3-0.1l0.5-0.5l0.3-0.7l0.3-0.4l0.3-0.1l0.4,0l0,0l0.2,0.1l1,0l0.8-0.1l2.3,1.5l2.3,1.2l3.3,0.9l2.5,0.9l0,0l0,0.1l-0.1,0.8
		l-0.2,2.6l-1.2,1.6l-0.6,0.6l-0.2,0.3l-1.1,4l-0.1,0.5l0,0.4l0.2,2.5l1,1.2l0.4-0.1l0.6-0.1l-0.4,2.2l-0.1,2.2l-0.4,1.5l-0.8,2
		l-0.7,0.5l-1,0.8l-0.2,0.3l-0.2,0.4l0,0l-0.7-0.1l-2.3-0.2l-1,0.1l-0.7,0l-0.5-0.3l-0.3-0.5l-0.4-0.1l-0.9,0l-1.3,0.1l-0.4,0.3
		l-0.3,0.1l-0.6,0l-3.1-1.7l-0.5-0.4l0,0l0-0.7l0.3-0.4l0.1-0.5l0.1-0.5l-0.6-0.5l0,0L259.1,79.4z`,
        cx: 265,
        cy: 70
	},
	{
		id: 'CL-ML',
		d: `M303.6,69.9L303.6,69.9l-0.5,0.5l-0.6,0.2l-0.5,0.1l-0.2,0.5l0.1,0.3l0.2,0.3l1.5,1.1l0.5,0.1l0.4,0l0.5,0.4
		l-0.1,0.6l-0.8,1.4l-0.3,0.3l-1.2,1.5l-0.3,1.2l-0.3,0.9l-0.3,0.5l-0.3,0.4l-0.4,0.2l-0.8,0.4l-0.1,0.9l0.1,0.3l0.2,0.4l0,0.3
		l-0.2,0.8l-0.2,0.3l-0.3,0.1l-0.4,0.2l-1.1,1.1l0.5,0.1l0.4,0l1.2,1l0,1.8l-0.1,0.4l-1.4,2.7l-1.8,2.1l-0.6,0.1l-0.2,0.2l-0.7,1.6
		l0,0l-0.2-0.3l-0.7-0.7l-1.6-1.5l-0.4-0.2l-0.6-0.1l-0.6,0l-0.8,0.3l-1.5,0.6l-0.5,0l-0.4-0.3l-0.3-0.6l-0.8-1l-2.3-0.9l-1.5-0.5
		l-0.4-0.2l-0.7-0.6l-0.3-0.5l-0.3-0.5l-0.8-0.8l-0.6-0.3l-0.8-0.1l-0.5,0l-0.6,0l-0.5,0l-0.9-0.1l-1.7-0.8l-0.3-0.3l-0.4-0.3l-1-0.3
		l0,0l0.2-0.4l0.2-0.3l1-0.8l0.7-0.5l0.8-2l0.4-1.5l0.1-2.2l0.4-2.2l-0.6,0.1l-0.4,0.1l-1-1.2l-0.2-2.5l0-0.4l0.1-0.5l1.1-4l0.2-0.3
		l0.6-0.6l1.2-1.6l0.2-2.6l0.1-0.8l0-0.1l0,0l0.1,0l0.7,0l0.6,0l0.4-0.1l0.8,0.1l0.4,0.2l0.5,1l0.1,0.4l0,0.5l0.1,0.3l0.6,0.3l0.5,0
		l0.5-0.2l0.2-0.3l1-1.6l2.6-0.4l1.7,0.1l0.4,0.2l0.6-0.1l1.1-0.5l1.3-0.5l0.4,0.4l0.7,0.4l0.4,0.1l1.4-0.1l1.8-0.2l1.1,0.4l0.3,0.2
		l0.2,0.4l0,0.5l0.1,1.2l1.9,1.6l1.3,0.2l0.8-0.3l0.4,0.1l1.3,3.5l-0.1,0.4L303.6,69.9z`,
        cx: 290,
        cy: 75
	},
	{
		id: 'CL-BI',
		d: `M315,71.1l0.1,0.4l-0.4,0.6l0.1,0.4L315,73l-0.2,0.6l-0.4,0.4l-0.1,0.4l-0.1,0.4l0.3,0.2l0,0.3l-0.3,0.5
		l-0.4,0.1l-0.4,0.5l-0.3,0.4l0.1,0.4l0.4,0l0.3,0l0.4,0l0.4,0.2l0.6,0l-0.1,0.3l0.1,0.5l0,0.5l0.3,0.4l-0.3,0.4l-0.1,0.4l-0.2,0.6
		l0.1,0.5l0.2,0.4l0.6,0.2l0.3,0.3l0.2,0.6l0.1,0.6l0,0.4l-0.4,0.6l0,0.2l0.3,0.2l0,0.3l-0.2,0.7l0.4,0.6l-0.2,1l0.2,0.5l-0.6,0
		l-0.6,0l-0.6-0.1l-0.2-0.5l-0.6-0.6l-0.4,0.1l-0.1,0.3l-0.3,0.4l0,0.6l-0.4,0.6l-1.2,0.8l0.3,0.3l0,0.8l-0.5,0.2l0,0.4l0.5,0.2
		l-0.2,2.1l-0.6-0.2l0.1-1.3l-0.4,0.2l-0.5-0.3l-0.3,0.5l-0.9,0.2l-0.4-0.1l-0.7,0.2l-0.8,0.8l-0.1,0.8l-0.7,0.3l-0.3,0.3l0.2,0.4
		l-1,0.3l-0.6,0.2l-0.4-0.3l-0.7,0.1l0,0.7l-0.7,0.2l-0.2,0l0.2,0.1l1.7,0.6l2,0.5l1.3,0.3l0.3,0.2l0.1,0.3l0,0.3l-0.1,0.4l-0.3,0.3
		l-0.2,0.5l3.4,0.2l3.2,0.2l1.4,0.3l0.5,0.2l0.5,0.3l0.3,0.3l0.3,0.6l0.2,0.8l0.1,0.4l0,0.3l0,0.6l-0.5,1.1l-0.2,0.4l-0.7,0.6
		l0.4,0.6l0.4,0.2l2.6,0.5l0.3-0.2l0.3-0.4l0.4-0.3l0.5-0.3l0.7-0.1l0.7,0.2l0.4,0.4l0.7,0.5l0.5,0.2l2.2-0.3l1-0.9l1.7-1l1.5-0.7
		l0.9-0.3l0.7-0.2l2.7,0.2l0.4,0.2l0.3,0.3l0.5,0.2l0.9-0.2l0-0.1l0.1-0.3l0.2-0.4l0.8-1.2l-3.7-3.7l-0.5-0.2l-0.4-0.1l-0.6-0.1
		l-0.4,0.3l-0.2,0.5l-1.4,0.5l-2.6-1.7l-0.5-0.4l-1.6-0.1l-0.4,0.2l-0.1,0.9l-0.4,0.3l-1.7-1.3l-0.3-1.9l0.3-0.6l0.4-0.6l0.3-0.5
		l0.4-2l0-1.2l1-0.9l1-0.4L328,89l0.4-0.7l0.1-0.3l0.1-0.5l0.1-0.5l0.2-0.4l1-1.2l0.4-0.2l0.6-0.5l0.2-0.3l0.3-2.1l-0.2-1.8l-0.1-0.4
		l0.2-0.5l0.7-0.5l0.7,0l0.5,0.1l0.6,0l0.4-0.1l1.1-0.3l0.4-0.2l0.4-0.5l-0.5-0.2l-0.5-0.2l-0.3-0.2l-0.6-1.3l-0.5-0.9l-0.2-0.2
		L333,75l-0.3-1.5l-0.1-3.3l0.1-0.9l-0.7,0l-3.4,1.4l-0.8,0.4l-0.8,0.2l-0.8,0.3l-0.9,0.3l-0.7,0l-0.6-0.1l-0.3-0.3l-0.4-0.5L321,71
		l-0.4,0.1l-0.5,0.4l-0.5,0.3l-1.4,0.4l-0.6-0.2l-0.6-0.5l-0.8-0.2L315,71.1L315,71.1z`,
        cx: 320,
        cy: 80
	},
	{
		id: 'CL-AR',
		d: `M336.9,104.9L336.9,104.9l0.1-0.3l0.2-0.4l0.8-1.2l-3.7-3.7l-0.5-0.2l-0.4-0.1l-0.6-0.1l-0.4,0.3l-0.2,0.5
		l-1.4,0.5l-2.6-1.7l-0.5-0.4l-1.6-0.1l-0.4,0.2l-0.1,0.9l-0.4,0.3l-1.7-1.3l-0.3-1.9l0.3-0.6l0.4-0.6l0.3-0.5l0.4-2l0-1.2l1-0.9
		l1-0.4L328,89l0.4-0.7l0.1-0.3l0.1-0.5l0.1-0.5l0.2-0.4l1-1.2l0.4-0.2l0.6-0.5l0.2-0.3l0.3-2.1l-0.2-1.8l-0.1-0.4l0.2-0.5l0.7-0.5
		l0.7,0l0.6,0.1l0.6,0l0.4-0.1l1.1-0.3l0.4-0.2l0.4-0.5l-0.5-0.2l-0.5-0.2l-0.3-0.2l-0.6-1.3l-0.5-0.9l-0.2-0.2L333,75l-0.3-1.5
		l-0.1-3.3l0.1-0.9l0,0l0.8,0l1.3,0.2l1-0.2l2.3-0.5l0.6-0.3l0.6-0.6l0.2-0.4l0.4-0.5l0.6-0.3l0.6,0.1l1.9,0.4l0.9,0.9l0.2,0.3l0,0.7
		l0,0.5l0.1,0.4l0.3,0.6l1.2,2l0.8,1.3l0.2,0.4l0.5,0.5l0.4,0.2l0.5,0.1l1,0l0.5,0l0.5-0.1l1.2-0.2l2-0.2l1.7,0.2l1.9,0.6l2.3,0.5
		l0.7,0.1l0.3,0.2l0.1,1.3l-0.2,0.4l-0.8,0.6l0,0.2l0,0l-0.4,0l-0.5,0.1L358,79l0,0.3l0,1l-0.6,0.8l-0.4,0.4l-0.3,0.5l-0.1,0.4
		l-0.2,2.3l0.1,0.4l0.2,0.4l0.4,0.4l0.3,0.3l0.2,0.4l0.5,1.3l-0.1,0.5l-0.3,0.7L357,91l0,0.4l0.2,1.1l0,0.4l0,0.4l-0.1,0.4l-0.2,0.4
		l-0.5,0.4l-0.8,0.4l-1,0.2l-0.2,0.3l0.1,2.1l0.9,0.7l0.5,0.3l0.4,0.4l0.2,0.4l0.1,0.4l0,0.4l-0.1,0.4l-0.1,0.5l0,0l-0.6-0.4l-2,0.1
		l-2.8,0.8l-7.2,2.6l-2.8,0.9l-2.2,0.4l-0.6,0l-1.1-0.2L336.9,104.9z`,
        cx: 340,
        cy: 90
	},
	{
		id: 'CL-LL',
		d: `M436.2,119.8l0.3,0.3l0,0.3l0,0.4l0.1,0.2l0.1,0.3l0.1,0.2l0.4,0.5l0,0.1l-0.1,0.2l-0.8,0.5l-0.2,0.1l-0.2,0.1
		l-0.2,0.1l-0.3,0l-0.6-1.2l0-0.2l0.6-1.2l0.1-0.1l0.3-0.3L436.2,119.8L436.2,119.8z M415.1,102.7l0.7,0l0,0.3l-0.5,0.1l-0.2,0.1
		l-0.2,0.1l-0.2,0.2l-0.7,1.3l-0.1,0.2l-0.1,0.5l-0.1,0.2l-1.1,0.4l-0.2,0l-0.1-0.2l0-0.3l0-0.5l0.1-0.3l0.2-0.3L415.1,102.7
		L415.1,102.7z M401.8,109.6l0.4,0.3l0,0.4l-0.3,0.4l-0.1,0.3l0.4,0.2l0.5-0.5l0.4-1.3l-0.3-0.4l-0.4-0.6l-0.9-1.5l-0.2-1.4l0-0.4
		l0.7-1l2.5,0.3l1.7-1.1l0.6,0.2l0.2,0.4l0.4,0.1l0.5-0.2l0.6-1l0.1-0.4l1.1-0.4l0.7,0.2l0.4,0.2l0.4,1.5l-0.1,0.8l0,0.3l0.5,1
		l0.2,0.3l0.4,0.2l1.6,0.1l0.5-0.5l0.2-0.4l0.3-0.2l0.7,1.5l-0.7,0.8l-0.8-0.3l0.1,0.5l0.8,0.4l1.8-0.2l2.7-2.7l0.2-0.7l0.6-0.8
		l0.3-0.2l1-0.2l0.4,0.1l0.2,1.1l0,0.5l0.3,0.5l0.8,0.1l0.5-0.4l0.6-0.7l0.3-0.1l0.9-0.5l0.5-0.4l0.6-0.2l0.3,0.3l-0.2,3.2l0.2,0.6
		l1.6-0.2l0.4-0.3l0.3-0.4l0.7-0.3l1.7,0.3l0.1,0.3l0.5,1.9l-0.4,0.5l-0.4,0.4l-0.1,0.4l0,1.3l-0.2,1.5l-0.5,1.8l-0.7,1.3l-0.2,0.3
		l-0.4,0.2l-2.7-1l-1.7-1.3l-0.6,0.2l-0.4-0.4l-1.6-0.6l-4.6-0.4l-1.4,0.1l-1,0.3l-0.2,0.3l-0.2,0.4l-1.6-0.2l-3.5-0.2l-0.3-0.1
		l-0.4-0.3l-0.4-0.3l-0.7-0.7l-1.5-0.4l-2.9-0.4l-1.1,0.6l-0.4,0.2l-0.4,0l-0.8-0.5l0.1-1.7L401.8,109.6L401.8,109.6z M372.6,107.9
		l0.3-4.3l1.4-0.9l0.3-1.4l-1.7-2.2l0.8-2.2l2.5-1.4l1.4-2l2.2-2.5l1.1-2.8l-2-1.6l-1.7-1.1l-0.3-2.2l0.3-2.6l0,0l0.8,0l0.5,0
		l0.8,0.3l0.4,0.3l0.3,0.3l0.4,0.4l0.5,0.3l1.5-0.5l2-0.9l0.8-0.2l1.1,0.3l0.9-0.3l3.4,0.3l0.5,0.1l0.5,0.2l1.2,0.2l0.6-0.1l0.9-0.4
		l2.2-0.3l1,0.6l0.4,0.3l0.5,0l2.2-0.9l1.8-0.9l0.5-0.2l1.1-0.2l1-0.1l0.6-0.4l1.2-0.1l0.7,0.7l0.9,1.8l-0.1,0.4l-0.5,0.1l-0.3,0.2
		l-0.1,0.5l0.2,0.3l0.3,0.3l2.6,1.3l0.6-0.3l0.6-0.7l0.4-0.2l3,0.9l0.4,0.2l0.4,0.1l0.4,0.1l1.5-0.2l1-0.3l0.8-0.1l1.8-0.1l0.5,0
		l0.6,0.1l0.4,0.2l0.4,0.1l1,0l0.6-0.3l1-0.6l0.3-0.4l0.3-0.8l0-0.6l0-0.4l0.1-0.9l0.1-0.5l0.8-1.4l2-0.1l0.4,0.1L430,79l-0.1,0.5
		l0.5,1.4l1.2,0.5l1.3,0.1l0.9-1.2l2-2.1L437,78l0.5,0.2l0.6,0.9l0.1,0.3l0.2,0.3l0.6,0l1.1-0.7l0.9-0.8l0,0l-0.5,1.1l0,0.5l1,2
		l0.8,0.2l0.2,2l-0.8,0.1l-0.5,0.3l-0.6,0.5l-0.3,0.4l-0.1,0.6l-0.1,0.3l-1.8,1.1l-0.8,0.1l-0.3,0.7l0.3,0.5l0.6,0.6l0.7,0.5l0.8,0.2
		l-0.4,2.8l-0.2,0.9l-0.2,0.3l0,0.9l0,0l-0.8,0l0.7,0.2l0,0l0,0.2l-0.1,0.4l-0.6,0.5l0.1,0.7l0.4,0.6l0,0l-0.7,0.1l-2.3-0.1l-2.3,1
		l-0.4,0.1l-2.5-0.5l-0.4-0.3l-0.4-0.5l-0.3-0.4l-0.2-0.3l-0.1-0.3l-0.2-0.5l-1.4,0l-0.8,0.3l-0.5-0.1l-1.1-0.6l-0.6-0.5l0-0.5l0.7-1
		l-2.6,0l-0.5,0.4l-0.3,0.4l-0.4,0.4l-0.6,0.2l-1.1,0.2l-1,0l-2.7,0l-1.3-0.5l-0.1-0.3l0.1-1.3l0.1-0.4L415,92l0.7-1.1l0.1-0.4
		l-0.2-0.2l-0.6,0.4l-0.2,0.7l-0.2,0.5l-0.5,0.6l-3.6,2.4l-0.8-0.7l-0.5-0.7l-0.7-2.1l0.2-0.5l0.7-0.4l0.4,0l3.7-1.3l0.2-0.3
		l-0.4-0.2l-1.4,0.1l-2.5,0.5l-0.8,0.2l-1.4,0.2l-0.5,0l-0.7-0.1l-0.5-0.1l-0.5-0.1l-0.6,0l-0.2,0.3l0,0.6l0.6,0.5l0.6,0.9l-0.2,1.8
		l-0.6,1.1l-0.8,0.6l-0.5-0.1l-1.2-0.7l-2.2-2.1l0-0.6l0.1-0.5l-0.4-1.5l-0.5-1l-0.7-0.8l-0.3-0.2l-3.8-0.3l0.2,0.5l3.4,0.5l0.6,0.3
		l0.5,1l0.5,1.5l0,0.5l-0.1,0.3l-0.4,0.6l-1.3,0.4l-0.7,0.5l-1.1,1l-0.3,0.6l-0.3,1.3l0.3,1.1l0.2,0.5l1.4,0.9l0.7-0.2l0.2-0.3
		l1.1-0.2l1.1,1l0.7,0.9l0.1,0.5l-0.2,1l-0.1,0.5l0,0.9l0.1,0.3l0.3,0.7l0.1,0.4l0,0.2l-0.6,0.9l-0.3,1.7l0,0.7l-1.5-0.2l-1-0.6
		l-0.2-0.3l0-0.5l0.1-0.5l-0.2-0.5l-1.7-0.9l0.4,0.4l0.7,0.3l0.4,0.3l0.1,0.4l-0.2,1.7l-0.5,0.9l-1.6,1.3l-0.7,0l-0.6-0.3l-1.3,0.1
		l-0.7,0.2l-1.4,0.5l-0.6,0.5l-0.5,0.2l-1.8,0.3l-2.1,0.3l-2.1-0.7l-4.4-1.6l-0.9-0.6L377,108l-0.7,0.1l-1,0.4L372.6,107.9
		L372.6,107.9z`,
        cx: 410,
        cy: 90
	},
	{
		id: 'CL-AI',
		d: `M473.2,94.3l-0.1-0.2l-1-0.4l-0.4,0.2l-0.2,0.9l0.3,0.3l0.1,0.4l-0.7,1.4l-0.9,0.8l-0.7,0.7l-0.9,1.1l-0.2,0.5
		l0.1,0.3l0.5,0.4l0.4,0.3l0.1,0.5l-0.1,0.7l-0.1,0.4l-0.2,0.3l-0.5,0.1l-1-0.2l-0.1-0.4l-0.1-0.9l-0.1-0.3l-0.8-0.5l-1.5,1l-0.9,0.4
		l-0.7-0.1l-0.5-0.3l-0.2-0.4l0-0.3l0.2-0.9l0-0.4l-0.5-1.3l-1.6-2.8l-0.7-0.8l-1.6-1.7l-1.2-0.5l-1.1-0.4l-3.3-1.2l-1.9-0.1l0.7,0.8
		l0.8,1.1l-1.1,1.5l-1,1.2l-0.6,0l-0.6,1.3l-0.3,0.9l-0.1,0.5l-0.1,0.6l-0.2,0.4l-1.5,1.4l-0.6,0.1l-0.6-0.1l-0.9-1l-0.9-1l-0.8,0
		l-1-0.5l-2.4-1.2l0-0.4l-0.2-0.6l-0.5-1.4l-0.5,0.1l0,0l0-0.9l0.2-0.3l0.2-0.9l0.4-2.8l-0.8-0.2l-0.7-0.5l-0.6-0.6l-0.3-0.5l0.3-0.7
		l0.8-0.1l1.8-1.1l0.1-0.3l0.1-0.6l0.3-0.4l0.6-0.5l0.5-0.3l0.8-0.1l-0.2-2l-0.8-0.2l-1-2l0-0.5l0.5-1.1l0,0l1-0.7l0.4,0l0.4,0.1
		l2,1.3l0.5,0.5l1.6,0.5l0.5-0.3l0.5-0.1l2.2,0l0.5,0.6l0.4,0.1l0.5-0.4l0.2-0.7l0-2.2l0-1.1l-0.2-0.9l-0.1-1.1l0.6-2.9l0.4-0.5
		l1.7-0.8l0.7,0.2l0.4,0.3l0.1,0.6l1.4,0.6l0.5-0.1l0.6-0.1l0.6,0.1l1,0.7l0.1,0.4l0,0.5l-0.1,0.4l-0.2,0.4l-0.5,0.7l-0.3,0.4
		l-0.1,0.3l0,0.4l0.2,0.5l0.4,0.7l0.1,0.3l0.1,0.3l-0.1,0.3l-0.1,0.3l-0.1,0.3l-0.2,0.8l0.2,0.3l0.4,0.3l0.2,0.4l-0.2,1.6l-0.1,0.3
		l-0.1,0.3l-0.2,0.4l-0.1,0.4l0.3,0.4l0.4,0.1l2.3-0.2l0.4-1.9l0.1-1.8l0.1-0.9l0.7-2.3l0.5-0.5l1.5-0.7l3.1-2.4l1.5-0.5l3.5,2.4
		l0.9,2l0,0.3l0.1,0.3l0.1,0.3l0.2,0.6l0.4,0.5l2,0.4l1.5,0.1l2.4-0.6l0.3-0.4l0.1-0.4l0.3-0.6l0.4-0.3l1.5-0.4l0.4,0.5l0.1,0.1
		l0.3,0.3l0.7,0.5l0.5,0.3l0.6,0.3l0.4,0.4l0.2,0.4l0.1,0.4l0,0.4l0.3,0.7l1-1l0.2-0.5l0.2-0.5l0.5-0.3l0.5-0.1l3.3-0.3l1.2-0.5
		l1-0.3l2.4-0.1l0.7,0.1l0.4,0.2l1.7,1.8l0.2,0.5l0.1,1l0.3,0.3l4.5,0.5l1.7-1.2l0.5-0.4l0.7-0.1l1,0.2l0.3,0.2l1,1.9l1.6,0.9
		l1.2,1.1l0.2,0.5l-0.2,0.5l0,0.4l0.2,0.5l0.1,0.3l0.2,0.3l0.3,0.2l0.6,0.1l0.2-0.3l0.3-0.5l1.9,0.3l0.5,0.1l0.4,0.2l1.2,0.7l0.6,0.3
		l0.9,0.4l3.2,1.1l0.5-0.1l0.4-0.2l0.6-0.5l0.3-0.4l0.1-0.6l1.5-1.2l2.4-0.3l3.3-0.2l1.3,1.9l0.4,0.9l0.5,0.9l0.2,0.3l0.4,0.2
		l0.5,0.1l1.5-0.4l0.5-0.1l1.4-0.2L541,89l1,0l0.5,0.1l0.5,0.4l1.7,1.7l0.4,0.4l0.2,0.3l0.2,0.4l0.2,0.4l0.1,0.6l0,0.6l0.1,0.3
		l0.2,0.5l0.3,0.4l0.4,0.3l1.5,1l3.4,1.4l0.5,0l0.2-0.1l0,0l-0.2,0.2l-3.6,4.3l-2.9,3.4l-4.2,5l0,0l-0.2,0l-0.4,0.7l-0.2,0.8l0,0.4
		l0.2,0.5l0.1,0.3l0.1,0.3l0,0.5l-1.1,0.8l-1.4,0.4l-0.9-0.6l-1.5-1.4l-0.5-0.7l0.4-0.7l1.1-1.5L537,110l-2.6-0.1l-0.4,1.3l-0.5,0.4
		l-0.2,0.3l-0.1,0.3l-0.1,0.8l-0.4-0.4l-0.3-1.2l-0.8,2.1l-1.7,1.9l-1.5,1.3l-1,1.3l0,0.5l-0.2,0.4l-1,0.2l-0.6-0.4l-0.9-1.5l0.6-1.6
		l0.7-0.9l0.5-0.3l0.4-0.2l0.8-0.3l1.8-0.3l0.9-0.9l-0.1-0.4l-2.7,1.1l-1.6,0.9l-0.4,0.1l-0.2-0.4l-0.1-1.5l0-1.8l0.3-0.4l0.5-0.4
		l0.3-0.8l0-1.5l-0.4-1.2l2.3-2.6l1-0.1l1.3-0.5l-0.8-0.2l-0.7-0.7l0.1-0.7l0.1-0.3l0.2-0.4l0-0.6l-0.6-0.7l-0.3-0.2l-0.6-0.1l-0.7,0
		l-0.5,0.2l1.1,0.1l0.4,0.3l0.5,0.6l0.1,0.3l-0.2,0.4l-0.8,1.2l-1.5,1.3l-1.4,1l-1.6,0.1l0-1.2l0.7-0.1l0.6-0.5l0.2-0.3l0.1-0.3
		l0.2-0.7l0.1-3l-0.5,0.6l-0.3,1l0,0.5l0.1,0.4l0.1,0.5l-0.1,0.5l-0.5,0.6l-3.4,2.9l-1.1,0.4l-0.6-0.1l-0.4-0.3l-0.4-0.4l-0.6-0.4
		l-1.4,0.3l-0.7,0.7l1.3,0.6l0.4-0.6l0.5-0.1l3.2,0.6l0.3,0.3l0.2,0.3l0.8,1.7l-0.2,1.2l-0.1,0.3l-0.9,0.4l-0.1,0.5l0,0.7l-0.5,2.4
		l-0.2,1.1l0.4,1.2l0.2,0.4l0.1,0.7l-0.1,1l-0.1,0.4l-0.5,1l-0.6,0.4l-3-1.9l-0.4-0.6l-0.1-0.3l0.1-1.2l0.1-0.4l0.7-0.8l1.1-0.3
		l1.3-0.5l0.7-0.6l0.2-0.5l0.1-0.5l-0.2-0.3l0,0.4l-0.2,0.7l-0.4,0.4l-0.8,0.2l-1-0.6l-0.3-1.3l0.3-0.8l0-0.4l-0.3-0.6l-1.7,0.2
		l0.5,1l0.5,0.5l0.4,1.7l-0.1,0.3l-3.1,3l-0.4,0.2l-1.4-0.8l-1.5-1.5l-0.7-0.3l-0.5-0.2l-0.5-0.2l-0.3-1.8l0.2-0.3l0.6-0.1l0.7,0
		l1.4-0.7l0.1-0.1l-0.3,0.1l-1.2,0.4l-0.5,0l-0.7-0.1l-0.3-0.2l-1-1l-2.4-1.5l-1.1,1.1l-0.1,0.3l0,0.3l0.2,0.3l0.3,0.4l-0.1,0.4
		l-2.4,1.6l-1.2,0.3l-0.5,0l-0.4,1.1l0.4,4l1.2,0l-0.1-0.4l0-0.6l0.5-1.6l0.6,0.2l0.3,0.4l0,0.4l-0.1,0.9l-0.5,1.1l-1.6,3.4l-0.8,1.7
		l-1.9,1l-0.6,0l-0.5-0.2l-1-0.8l-1-1l-1.4,0.1l0.5,0.3l0.7,0.2l1.5,1.4l0.5,0.5l0.7,1l0.7,2.3l-0.1,0.6l0,0.6l0.3,1l0.5,0.9l0.3,0.4
		l1.3,0.8l0.3-0.3l-0.6-0.7l-1.1-1.5l0-0.4l0.2-0.4l3.4-1.1l0.5,0.1l0.6,0.9l0.3,0.8l0.1,0.3l0,0.1l0,0.1l-0.3,0.9l-1.2,1.1l-1.8,1
		l-1.4,0.1l-1.9-0.2l-1.3-0.9l-0.1-0.3l0-0.4l0.1-0.5l-0.4-0.7l-1.6-1.8l-0.7,0.1l-0.8-0.6l-0.3-0.3l-0.5-0.9l0.6-0.6l0.2,0l-1.2,0
		l-1-0.2l-1.8-1.6l-0.1-0.6l0-1.3l-2.1-1.9l-1.1-0.5l-2.7-0.9l-1.7-0.5l-0.3-0.2l-0.4-3.1l-0.3-1.3l0.2-0.5l0.7-0.3l-0.5-0.7
		l-0.3-1.5l0.2-0.3l0.5-0.4l2.2-0.8l2.2,0.4l0.6,0.2l0.6,0.3l0.3,0.3l0.2,0.2l0-0.3l-0.8-0.9l-1.1-0.3l-0.4,0l-0.4,0.1l-0.4-0.2
		l1.1-1.1l0.4-0.1l0.6,0l0.5,0.2l0.3,0.2l0.4,0.4l0.6,0.7l0.4,0.7l0.5,0.9l0.5,1.7l0,0.4l-0.2,0.4l-0.5,0.6l-0.3,0.3l-0.4,0.6
		l1.2-1.2l0.2-0.3l0.2-0.3l0-0.4l-0.4-1.4l-0.3-1.1l-0.3-0.4l-0.4-0.7l-0.1-0.3l1.9-0.6l1-1.5l0.2-0.4l0-0.1l-0.2-0.1l-0.6,0.4
		l-0.2,0.4l-0.3,0.5l-1.4,1.2l-1.1-0.5l-0.3-0.3l-0.1-1l2.1-1.9l1.1,0l0.8,0.1l0.6,0.2l0.5,0.2l1.3,1l0.5,0.4l1.8,1.1l0.9-1.2l0-0.5
		l-0.2-0.4l-0.3-0.2l-1.7-0.7l-0.5-0.2l-1.1-0.3l-3.1-2.4l-1.7-0.5l-2.4-1.5l-0.2,0.3l2,1.3l1,0.6l0.7,0.2l0.9,0.4l0.5,0.4l0.2,0.5
		l-0.9,0.1l-0.4-0.1l-1.1-0.5l-0.8-0.2l-0.9-0.2l-0.7,0l-1.2,0.4l-0.9,0.3l-3.5-1.7l-1.7-1.4l-0.3-0.3l-0.4-0.4l-0.2-0.4l0-0.3l0-0.7
		l0.1-0.8l-0.1-0.4l-0.3-0.3l-0.6,2l0.1,0.5l0.2,0.6l0.4,0.5l0.8,0.4l0.3,0.2l1.1,1.1l0.1,0.3l-1.9-0.1l-4.7-0.7l-1.6-1.7l-1.1-1.4
		l-0.5-1.1l0.4-0.4l0.6-0.1l0.5-0.4l0.5-0.6l0.9-1.3l0.3-0.9L473.2,94.3L473.2,94.3z M458.4,94.4L458.4,94.4l0.6,0.8l0.4,0.7l1,0.9
		l0.3,0.1l0.3,0.2l0.1,0.1l0.8,0.9l0.4,0.9l0.1,0.4l0.1,0.5l0,0.2l-0.1,0.2l-1.7,1.5l-0.2,0.1l-0.2,0L460,102l-0.5-1.4l0.1-0.3
		l0.1-0.2l0.2-0.2l-0.1-0.6l-0.1-0.1l-0.3,0.4l-0.4,0.9l0.4,0.6l0.2,0.2l0.1,0.1l0,0.2l-0.1,0.2l-0.2,0.2l-0.9,0.5l-0.5,0.2l-1.3,0.3
		l-0.8-0.6l-0.2-0.1l-0.1-0.4l0-0.2l-0.3-1.4l-0.9-0.2l-0.8-0.1l-0.3,0l-0.2,0l-0.8-0.3l-0.2-0.1l-0.1-0.2l-0.6-1.8l-0.3-1.3l0-0.2
		l1.4-1.7l0.4-1.1l0.2-0.3l1.1-0.6l0.2-0.1l0.2,0l1.6,1.1l0.1,0.1l0.1,0.2l0.1,0.3l-0.1,0.9l-0.1,0.2l-0.2,0.2l-0.3,0.8l0.3,0
		l0.2-0.1l0.6-0.9l0.1-0.4l0.1-0.6l0.3-0.1l0.4,0.2l0.3,0.2L458.4,94.4L458.4,94.4z M540.8,126.6l0.1,0.2l0.1,0.3l-1.5,0.7l-1,0.2
		l-2,0.3l-0.3,0l-0.9-0.3l-0.8,1.4l0.5,0.9l0.1,0.2l0.1,0.3l0,0.3l0,0.2l-0.2,0.3l-0.2,0.1l-0.2,0l-0.3-0.1l-0.2-0.3l-0.2-0.3
		l-0.2-0.4l0-0.5l-0.1-0.4l-0.1-0.2l-0.2-0.3l-0.2-0.2l-0.4-0.2l-0.3-0.2l-0.1,0.2l0.4,0.7l0.3,1.9l-0.2,0.2l-4.6,0.6l-0.2-0.1
		l-0.8-0.6l-0.3-0.4l-0.6-2.1l0-0.3l0.2-0.2l2.7-0.9l0.4-0.1l1.2-0.1l1.6-0.5l0.9-0.4l0.5-0.4l0.9-0.5l2.1-0.8l1.8-0.2l0.4,0.1
		l0.2,0.1L540.8,126.6L540.8,126.6z M540.5,118.4l-1.2,1.8l0,0.2l0.1,0.2l0.1,0.2l0,0.3l-0.3,1.7l-0.5,0.9l-0.2,0.2l-0.2,0.1
		l-1.6,0.2l-0.4-0.1l-0.3-0.1l-0.2-0.1l-1.2-1l-0.1-0.2l-0.1-0.9l0-0.2l0.3-0.1l0.5-0.3l0.3-0.1l0.2-0.1l0.5-0.6l-0.8-0.7l-0.2,0
		l-0.3,0.2l-0.1,0.1l-0.1,0.4l-0.9,0.5l-0.2,0.1l-0.8,0.2l-0.3,0l-0.3,0l-3.2-0.6l-0.3-0.1l-0.2-0.1l-0.2-0.2l0.1-0.2l0.4-0.3
		l0.2-0.1l0.3-0.1l0.4,0l0.8-0.1l1.5-0.2l2.3-1.3l1,0.3l1.2,0l1-0.1l0.4-0.1l0.9-0.7l0-0.2l-0.2-0.1l-0.2,0l-0.2,0.1l-0.3,0l-0.1-0.2
		l0.1-0.2l0.1-0.2l0.2-0.1l0.3,0l0.6,0.1l0.4,0.1l0.2,0.1l0.1,0.2l0.5,0.7l0.1,0.3l0,0.3L540.5,118.4L540.5,118.4z M535.3,124.2
		l0,0.2l-0.1,0.2l-4.5,2.1l-2.7,0.9l-0.6,0l-0.1-0.5l0.2-0.9l0.4-1.7l0.2-0.4l1-1.2l0.4-1.6l0.2-0.2l0.3,0l0.4,0l3.1,0.4l0.2,0.1
		l0.1,0.2l0,0.4l0.5,0.7l0.3,0.3l0.3,0.4L535.3,124.2L535.3,124.2z M469.5,106.6l-0.3,0.9l-0.6,0.5l0,0.6l0.2,0.8l0,0.2l0,0.6
		l-0.1,0.2l-0.1,0.2l-0.3,0.2l-1.5,1.3l0.2,0.4l0.2,1l0,0.2l-0.1,0.1l-0.5,0.1l-0.6,0.1l-0.2,0l-0.4-0.1l-0.3-0.2l-0.5-0.5l-0.2-0.2
		l-0.1-0.3l0-0.3l0.1-0.1l0.1-0.3l-0.1-0.6l-0.2-1.1l-0.1-0.3l-0.3-0.5l-0.1-0.2l-0.1-0.2l0-0.3l0.1-0.3l0.1-0.2l0.7-0.9l0.1-0.1
		l0.1-0.1l0.2-0.1l0.9-0.6l0.6-0.2l0.2,0l0.3,0l0.6,0L469.5,106.6L469.5,106.6z M525.4,114.3l-0.3,0.5l-0.7,1.8l-0.2,0.3l-0.2,0
		l-1.3-1.1l-0.2-0.1l-0.2-0.3l-0.7-1.3l-0.1-0.3l1-3.2l0.7-1.7l0-0.4l-0.1-0.2l-0.4-0.4l-0.1-0.2l0-0.2l0.1-0.2l0.3-0.2l0.2-0.1
		l0.3,0.1l0.8,0.3l0.2,0.3l0.2,0.5l0,0.2l0,0.5l0,1.5l0,0.3l-0.1,0.3l-0.1,0.2l-0.1,0.3l0,0.2l0.7,2.3L525.4,114.3L525.4,114.3z
		 M460,114.2l0,0.3l-0.1,0.4l-0.2,0.3l-0.1,0.2l-0.2,0.2l-0.4,0.3l-1.2,0.6l-0.2,0l-0.9,0l-0.2-0.2l-0.5-1.9l0-0.2l0-1.3l0.1-2.3
		l0.2-0.5l0.1-0.2l0.2-0.3l0.1-0.1l0.2-0.2l0.6-0.4l0.3-0.1l0.6,2l1.1,2.4l0.2,0.3L460,114.2L460,114.2z M486.2,125.4l-0.2,0.2
		l-0.2,0.1l-0.6,0.2l-0.2-0.1l-0.1-0.2l-0.5-1.7l0.1-0.2l-0.4,0.1l-0.1,0.4l0.2,0.4l0,0.5l-0.1,0.2l-0.3,0.1l-1.8,0.5l-0.2-0.1
		l-0.2-0.2l-0.1-0.3l0.5-1.3l0.1-0.2l0.2-0.2l-0.5-0.9l-1.1-1.2l-0.4-0.7l-0.1-0.3l0.3-0.2l0.3,0l0.3,0.1l2.8,0.6l0.2,0.1l0.2,0.1
		l0.5,0.4l1.5,1.5l0.1,0.2l0.1,1l0,0.3l-0.2,0.5L486.2,125.4L486.2,125.4z M540.6,132.4l-0.5-0.4l0.1-0.4l-0.1-0.4L540,131l-0.2-0.4
		l-0.1-0.2l-0.3-0.3l-0.3-0.1l-0.1,0.1l0.3,0.3l0.2,0.2l0.1,0.2l0.1,0.3l0,0.2l-0.1,1l-0.3,0.7l-0.6,0.3l-2.3-0.2l-0.8-0.4l-0.1-0.3
		l-0.3-3l0.1-0.7l0.3-0.1l3-0.3l0.2,0.1l0.6,0.7l0.3,0.3l0.7,0.9l0.3,0.4l0.1,0.2l0.1,0.2l0.1,0.4l0,0.5l-0.1,0.3L540.6,132.4
		L540.6,132.4z M479.6,116.8l-0.1,0.2l-0.3,0.2l-0.4,0.2l-0.3,0l-0.2-0.1l-0.4-0.3l-0.4-0.2l-0.8,0l-0.9,0.2l-0.6,0.1l-0.3,0l-0.3,0
		l-0.4-0.1l-0.2-0.1l-0.4-0.1l-0.4-0.2l-0.4-0.2l-0.1-0.3l0.6-1.3l0.2-0.2l0.2-0.2l0.6-0.3l0.4-0.1l1-0.3l0.8-0.2l0.8,0.3l0.2,0.1
		l1.7,2.2l0.1,0.2L479.6,116.8L479.6,116.8z M527.3,122.3l0,0.2l-0.2,0.8l-0.4,0.9l-0.1,0.2l-0.2,0.1l-0.4-0.2l-0.2,0.3l-0.1,0.4
		l0,0.4l0.2,0.3l0.4,0.3l0.4,0.2l0.1,0.2l0.1,0.3l0.1,0.5l-0.1,0.3l-0.2,0.2l-1.2-0.8l-0.3-0.4l-0.1-0.2l-0.3-1l-0.1-0.4l0-0.3
		l-0.9-1.3l-1.4-1l-0.4-0.8l0.1-0.2l0.7-0.1l0.2,0l0.8,0l3,0l0.6,0.7l0.1,0.1L527.3,122.3L527.3,122.3z M479.1,105.3l0,0.1l-0.1,0.3
		l-1.7,1.5l-2.1,0.1l-0.3,0l-0.3,0l-0.2-0.1L474,107l-1.1-0.8l-0.1-0.1l-0.1-0.3l0-0.3l0.3-1l0.2-0.2l0.7,0l3.9,0.4l1.1,0.3
		L479.1,105.3L479.1,105.3z M464.5,114.4l0,0.1l-0.3,1l-0.2,0.1l-0.5-0.2l-2.1-2.1l-0.2-0.2l-0.1-0.3l0-0.3l0.6-2.6l0.2-0.2l0.7-0.5
		l0.2-0.1l0.2,0.2l0.3,0.5l0.1,0.3l0.3,0.9l0,0.2l0,1.1l-0.1,0.7l0.1,0.3l0.1,0.2L464.5,114.4L464.5,114.4z M458.7,106.9l0,0.2
		l-1.2,0.9l-1.9,0.1l-0.3-0.1l-0.2-0.2l-0.4-1.4l0-0.2l0.1-0.2l0.2-0.2l1.1-0.5l1.7-0.6l0.2,0l0.1,0.2l0.5,1.5L458.7,106.9
		L458.7,106.9z M442.8,110.6l-0.4,0.4l-0.4,1.4l-0.3,0.7l-0.2,0.1l-1.1-0.3l-0.2-0.2l-0.1-0.2l0.1-0.9l0.1-0.2l-0.3-1.9l-0.7-0.8
		l1.1-1l0.9-0.3l0.4-0.1l0.1,1.3l-0.2,0.2l-0.1,0.2l-0.2,0.1l-0.2-0.2l-0.2-0.1l-0.1,0.2l0,0.4l0.2,0.8l0.4,0.1l0.2-0.2l0.2-0.1
		l0.2-0.4l0.6,0.4l0.1,0.2L442.8,110.6L442.8,110.6z M471,110.4l0,0.2l-0.6,1.7l-0.2,0.2l-1.3,0.3l-0.4-0.3l-0.4-0.9l0-0.2l0.1-0.2
		l0.1-0.1l0.5-0.2l0.1-0.1l0.1-0.2l0.1-0.2l0-0.3l0-0.5l0-0.2l-0.1-0.5l-0.1-0.4l0.1-0.4l0.1-0.2l0.1-0.1l0.3-0.2l0.4-0.1l0.4,0
		l0.3,0.1l0.1,0.1l0,1l0.1,1.2L471,110.4L471,110.4z M533,117.1l-0.1,0.1l-1.9,0.9l-0.2,0l-0.4,0l-0.5,0l-0.8,0l-0.7-0.5l0-0.2
		l0.2-0.3l0.4-0.5l1.5-1.6l0.7-0.3l0.9,0.6l0.1,0.2L533,117.1L533,117.1z M488.4,106.9l-0.4,0.5l-0.1,0.1l-2.3,1.7l-0.3,0l-0.6-0.2
		l-0.2-0.2l-0.1-0.2l0-0.7l0-0.3l0.5-1.3l0.5-0.6l0.2-0.2l0.3-0.1l0.4,0.1l1.5,0.8l0.4,0.2l0.1,0.2L488.4,106.9L488.4,106.9z
		 M454.9,116.3l-0.4,1.6l-0.2,0.3l-0.2,0.1l-0.7,0.2l-0.3-0.1l-0.3-0.3l-0.1-0.2l-0.9-2.7l0.2-0.7l0.8-0.5l0.3-0.2l0.2,0l0.1,0.3
		l0.3,1.3l0.2,1l0.1,0.2L454.9,116.3L454.9,116.3z M478.5,110.1l0,0.1l-0.1,0.8l-0.2,0.2l-1.1,0.5l-0.7,0.3l-0.4,0l-0.9-0.7l-0.3-0.5
		l0-0.2l0.4-0.7l0.7-0.7l0.6-0.3l0.3,0l0.6,0.1l0.8,0.3L478.5,110.1L478.5,110.1z M542.9,132.5l0,0.1l-0.1,0.2l-0.2,0.2l-0.3,0.1
		L541,133l0-0.3l0.3-0.6l0.2-0.6l0-0.3l-0.1-0.2l-0.1-0.2l-0.8-0.8l-1-1.1l0.5-0.7l0.6-0.2l0.2-0.1l0.3,0l0.4,0.3l0.2,0.2l0.9,2.4
		L542.9,132.5L542.9,132.5z M475.5,112.3l-0.1,0.1l-0.2,0l-0.5-0.1l-0.3-0.1l-0.2-0.1l-0.2-0.2l-0.4,0l-0.3-0.1l-0.3-0.2l-0.3-0.1
		l-0.2-0.1l-0.2-0.2l0-0.2l0.1-0.2l0.1-0.2l0-0.3l0-0.3l0.1-0.2l0.1-0.2l0.1-0.1l0.2-0.4l0.2-0.2l0.1-0.2l-0.1-0.2l-0.3-0.3l-0.1-0.2
		l0.2-0.2l0.3-0.1l0.4,0.1l0.4,0.1l0.3,0.1l0.4,0.2l0.4,0.1l0.1,0.2l-0.2,0.3l0,0.1l-0.1,0.2l-0.4,0.3l-0.1,0.3l0,0.3l-0.2,0.5
		l-0.1,0.5l0.1,0.1l0.2,0.2l0.1,0.3l0.2,0.2l0.4,0.1l0.2,0.1L475.5,112.3L475.5,112.3z M508.3,112.4l-0.1,0.2l-0.8,0.6l-0.3,0l-0.3,0
		l-1.4-0.4l-0.2-0.1l-0.1-0.1l-0.1-0.2l0.1-1.9l0.6-0.8l1.7,1.1l0.3,0.3l0.1,0.2l0.1,0.2l0.3,0.7L508.3,112.4z M463,108.3l-0.5,0.5
		l-0.5,0.5l-0.3,0.2l-0.2,0.1l-1.5,0.1l-0.3-0.1l-0.3-0.2l-0.1-0.2l-0.1-0.2l0-0.3l0.1-0.3l0.2-0.1l2.3-0.9l0.6-0.1l0.3,0.1l0.2,0.1
		l0.2,0.2l0.1,0.3l0,0.2L463,108.3L463,108.3z M535.7,113.5l-0.3,0.2l-0.1,0.2l-0.2,0.3l-0.7,1l-0.2,0.3l-0.3,0.7l-0.2,0.2l-0.3,0.2
		l-0.2,0.1l-1.4-1.8l-0.1-0.2l0-0.2l0.1-0.3l0.2-0.2l0.4,0l0.6,0.2l0.3,0l0.3-0.2l0.6-0.4l0.3-0.3l0.2-0.3l0.2-0.2l0.3,0.1l0.1,0.2
		l0.3,0.4L535.7,113.5L535.7,113.5z M478.7,120.3l-0.2,0l-0.4,0l-0.3,0l-1.1-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.2
		l-0.4-0.8l0-0.3l0.1-0.2l0.2-0.1l1.9,0.2l1.1,0.4l0.2,0.2l0.1,0.2l0.1,0.3l0,0.2l-0.2,0.7l-0.1,0.1L478.7,120.3L478.7,120.3z
		 M483.8,108.9l-0.1,0.2l-0.2,0.1l-0.6,0.2l-0.3,0l-0.3-0.1l-0.2-0.1l-0.6-0.4l-0.3-0.2l-1.3-2.1l0-0.2l0.3-0.2l0.2-0.1l0.3-0.1
		l1-0.1l-0.1,0.2l-0.1,0.1l0,0.2l0.1,0.6l0.1,0.3l1,0.2l0.9,0.4L483.8,108.9L483.8,108.9z M522.4,126.6l-0.4-0.1l-0.2,0l-0.2,0.1
		l-0.2,0.6l0,0.3l0,0.3l-0.1,0.3l-0.2,0.2l-0.6-0.5l-1-1.8l0.1-0.3l0.6-0.4l0.4-0.2l0.8-0.3l0.3,0.1l0.4,0.2l0.2,0.1l0.2,0.2l0.2,0.4
		l0.2,1L522.4,126.6L522.4,126.6z M482.4,110.6l-1.6,0.7l-0.6,0.3l-0.5,0.2l-0.4-0.2l-0.2-0.1l-0.4-0.5l-0.1-0.2l0.3-1l0.7,0l1.5,0
		l0.3,0.1l0.5,0.2L482.4,110.6L482.4,110.6L482.4,110.6z M461.1,116l0,0.3l-0.5,0.9l-1.4,0.9l-0.3,0l-0.2-0.1l-0.1-0.2l-0.3-0.8
		l0.1-0.3l2.3-1.6l0.2,0l0.2,0.9L461.1,116z M471.9,115.9l-0.8,1.3l-0.1,0.2l-0.2,0.2l-0.4,0.3l-0.2,0.1l-0.3-0.1l-0.2-0.2l-0.1-0.3
		l0-0.2l0-0.2l0-0.6l0.1-0.3l0.2-0.2l0.3-0.2l0.3-0.2l1.3-0.6l0.2,0.1l0.1,0.2l0.1,0.3l-0.1,0.4L471.9,115.9L471.9,115.9z M455,111.2
		l-0.1,0.4l-0.1,0.5l-0.1,0.3l-0.1,0.1l-0.3,0.1l-1.4-0.1l0.2-1.5l0.1-0.4l0.1-0.3l0.1-0.2l0.2-0.2l0.2-0.1l0.3-0.1l0.4,0l0.3,0.1
		l0.1,0.2L455,111.2L455,111.2z M444.4,98.8l-0.1,0.4l-0.5,1.1l-0.3,0.2l-0.4,0.1l-0.3,0l-0.5-0.2l-0.2-0.2l-0.5-0.9l-0.1-0.3l0-0.2
		l0.1-0.2l0.1-0.1l0.3,0l2.1,0.2L444.4,98.8L444.4,98.8z M450.5,110.7l0,0.3l-0.1,0.2l-0.5,0.6l-0.3,0.2l-1.7,0.5l-1-0.9l0-0.2
		l1.1-0.9l0.6-0.5l0.3,0.8l0.3,0l0.2,0L450.5,110.7L450.5,110.7z M446.6,105.8l-1.8,1l-1,0.4l-0.3,0l-0.3-0.1l-0.4-0.2l-0.2-0.1
		l0-0.3l1.4-1l1.4-0.3l0.5,0.1l0.5,0.4L446.6,105.8L446.6,105.8z M536.9,115.8l-0.7,0.3l-1.3,0.7l-0.2,0.1l-0.2,0l-0.3-0.2l0-0.2
		l0.2-0.4l0.6-1.1l0.5-0.7l0.2-0.3l0.2-0.1l0.2,0l0.2,0.2l0.2,0.3l0.4,0.8l0.2,0.4l0,0.2L536.9,115.8L536.9,115.8z M469.9,115.2
		l-0.6,0.7l-0.2,0.2l-0.1,0.1l-0.2,0.1l-0.3,0l-1.1-0.2L467,116l0-0.4l0.1-0.2l1.1-1.2l1.8,0.4l0.1,0.2l0,0.2L469.9,115.2
		L469.9,115.2z M457.9,117.2l0,0.5l0,1.8l-0.3,0.7l-0.3,0l-0.2-0.1l-0.6-0.6l-0.1-0.2l-0.1-0.3l0-0.2l0.1-0.4l0.2-1.1l0.1-0.2l1-0.1
		L457.9,117.2z M508.1,116.6l-0.7,0.3l-0.7,0l-0.2-0.1L506,116l-0.1-0.2l-0.1-0.5l0-0.4l0.1-0.3l0.2-0.1l0.3,0l0.4,0.1l0.2,0.1
		l1.3,1.6l0.1,0.2l-0.2,0.1L508.1,116.6L508.1,116.6z M462.6,125.9l0,0.1l-0.1,0.2l-1.4,0.5l-0.2,0.1l-0.2,0l-0.3,0l-0.3-0.1
		l-0.4-0.3l-0.1-0.3l0.1-0.2l1.3-0.8l0.9,0l0.6,0.7L462.6,125.9L462.6,125.9z M452.6,110.1l-0.8,1.5l-0.2,0.2l-1.1,0.3l-0.1-0.2
		l0.7-2.3l1.6-0.1l0.2,0.1l-0.1,0.3L452.6,110.1L452.6,110.1z M455.5,115.2l0,0.1l-0.1,0.2l-0.3,0.2l-0.2,0.1l-0.2,0l-0.2-0.1l-0.5-1
		l-0.1-1.4l1.1-0.8l0.2,0.7L455.5,115.2L455.5,115.2z M536.1,110.5l0,0.1l-0.2,0.3l-0.8,1.2l-0.9,0.9l-0.3,0.1l-0.3-0.1l-0.1-0.3
		l0-0.3l0-0.2l0.1-0.3l0.9-1.4l0.1-0.2l0.2-0.1l0.2-0.1L536.1,110.5L536.1,110.5z M484.6,105.8l0,0.1l-0.4,1.4l-0.3,0.4l-0.3,0.1
		l-0.3,0l-1.3-0.7l-0.2-0.2l-0.1-0.2l0.6-0.6l2-0.4L484.6,105.8L484.6,105.8z M460.5,106.1l-0.3,0.5l-0.2,0.3l-0.8,0.1l-0.3-0.1
		l-0.4-1.6l0-0.2l0.2-0.2l0.2,0l1.5,0.2l0.3,0.2l0.1,0.3l-0.1,0.4L460.5,106.1L460.5,106.1z M447.9,108.7l-0.2,1.1l-0.1,0.5l-0.1,0.2
		l-0.1,0.1l-0.2,0.1l-0.2,0.1l-0.6-0.5l-0.3-0.4l-0.1-0.3l0.1-0.2l0.4-0.6l0.8-0.6l0.4,0.1l0.2,0.1l0.1,0.2L447.9,108.7L447.9,108.7z
		 M461,111.1l0,0.4l-0.6,1.5l-0.2,0.2l-0.2-0.3l-0.1-0.8l-0.1-0.4l-0.1-0.2l-0.2-0.3l-0.2-0.2l-0.1-0.3l0.1-0.6l0.2-0.1l0.8,0.1
		l0.2,0.2l0.1,0.2l0.2,0.5L461,111.1L461,111.1z M521.3,124l0,0.1l-0.5,0.6l-0.2,0.1l-1.2,0.4l-0.1-0.1l0-0.3l0.2-1.2l0.1-0.1
		l0.3-0.3l0.5-0.3l0.2-0.1l0.3,0.2l0.2,0.6L521.3,124L521.3,124z M457.7,121.7l-0.1,0.1l-0.5,0.3l-0.2,0.1l-0.4,0.1l-0.4,0l-0.8,0
		l-0.3-0.1L455,122l0-0.2l0.5-0.8l0.2-0.1l0.3-0.1l0.3,0l1.2,0.2l0.2,0.3l0.1,0.2L457.7,121.7L457.7,121.7z M472.6,109.2l-0.3,1
		l-0.1,0.4l-0.2,0.1l-0.3-0.1l-0.2-0.2l-0.2-0.6l0-0.3l0-1.5l0.1-0.2l0.3,0l0.4,0l0.5,1l0,0.2L472.6,109.2L472.6,109.2z M450.6,108.6
		l-0.3,1.4l-0.2,0.2l-0.7,0.1l-0.2-2.2l0.1-0.2l0.7-0.1l0.3,0l0.1,0.2l0.1,0.2l0.1,0.3L450.6,108.6L450.6,108.6z M438.6,97.1
		l-0.4-0.6l-0.1-0.7l0.6-0.5l0.1-0.4l0-0.2l0,0l0.2,0.2l0.1,0.9L438.6,97.1L438.6,97.1z`,
        cx: 480,
        cy: 90
	},
	{
		id: 'CL-MA',
		d: `M616,97.1l0,0.3l-0.1,0.5l-1.2,0.5l-2.6,0.3l-2.5,0.1l-2,0.3l-0.5,0.2l-0.7,0.7l0.5-0.1l1.2-0.5l1.2-0.2l7.6-0.8
		l0.8,0.1l0.2,0.4l-0.3,0.5l-2.3,2.8l-0.4,0.3l-0.7,0.2l-0.9,0l-1.5,0.5l-1.7,0.6l-0.4-0.7l0.5-0.2l2.5-1.2l1.8-1.1l-0.2-0.5
		l-2.4,1.1l-3.6,1.6l-0.3-0.5l-0.3-0.5l-0.8-0.6l0.6,2.1l1.6,1.2l-1.7,0.9l-1.6,0.4l-0.2,0.9l-0.3,0.7l-4.3,0.4l-0.5-0.1l-0.8-0.3
		l0.4-1.3l1.7-1.5l0.9-0.4l1.8-0.8l1-0.2l-0.1-0.2l-0.6-0.1l-4.2,1.4l-4.5,1.3l-0.4-0.6l0.8-0.5l0.6-0.2l-2.5,0.3l-0.4,0.3l0.2,0.3
		l1.7,1.4l0.3,0.3l0.1,0.3l-0.7,2.6l-0.5,0.6l-2.5,0.4l-0.6,0.9l-0.3,0.3l-2.4,0.2l-0.6-0.3l-0.5-0.6l-0.2-0.6l0-3.3l0.2-0.3l0.8-0.5
		l-2.8-1l-2.3,0l-0.6-0.4l-0.4-1.2l0-0.5l0.6-0.8l0.7-0.2l0.3-0.4l-1-0.4l-0.9,0.9l-0.1,0.7l-0.2,0.6l-1.1,1l-0.5,0l-0.5-0.3
		l-0.1-0.3l-0.1-0.5l-0.2-0.4l-0.3-0.2l-1.9-0.4l2.6,2.6l0.5,0.2l1.4,0.4l1.9,0.4l0.5-0.1l0.6-0.1l0.4,0.1l1.5,1.5l0.2,0.3l-0.1,1.4
		l-1.3,1.1l-1.5,0.6l-2.3,1l-2.7,0.8l-0.4-1.1l0.5-1.3l0.3-0.4l0.3-0.3l0.3-0.3l0.2-0.4l0.3-1.9l-0.5,0.7l-0.2,0.7l-0.9,1.2l-0.8,0.6
		l-0.2,2.4l-1.8,2.6l-3.1,3.1l-0.7,0.1l-0.6-0.3l-0.6-0.5l-0.5-0.7l-0.5-1.3l0.3-0.8l-0.2-1.2l1-0.8l0.7-0.3l1.1-1l1.5-3.4l0.2-0.9
		l0-0.3l-0.2-0.2l-1.7,3.8l0,0.3l-0.1,0.3l-0.5,0.4l-4.1,2.4l-0.9-0.1l-0.5-0.3l0.1-1l0.2-0.7l1.5-1.4l1.3-3.3l-0.7,0l-0.6,0.2
		l-0.7,1.5l-0.5,1.3l-1.4,2.8l-0.6,0.4l-1.5,0.4l-0.9-1.5l-0.3-0.7l-0.1-0.7l0.2-0.4l-0.1-0.6l-0.7-0.1l0.3,1.8l0.4,1.3l-0.1,0.3
		l-0.9,0.3l-1.1,0.1l-0.5-0.1l-1.1-1.1l-0.7-1.9l-0.1-0.3l0.5-2l0.5-0.3l1.2-0.3l0.6-0.4l0.3-0.5l0.1-0.4l0.9-0.9l1.3-0.4l-0.7-0.2
		l-1,0.3l-0.8,0.4l-0.3,0.3l-0.1,1.1l-1.5,0.4l-1.3,0l-0.4,0.6l0.2,1.1l-0.5,1.5l-0.3,0.2l-1.5,0.2l-3-0.5l-0.3-0.2l0-0.4l0.5-0.5
		l0.4-0.2l0.5-0.3l0.4-0.7l0.1-0.4l-0.2-0.5l-0.4,0.6l-0.1,0.5l-0.2,0.3l-1.3,0.8l-3.5,0.5l-0.4-0.2l-0.6-0.4l-0.3-0.2l-0.2-0.3
		l0-0.4l0.3-0.4l0.3-0.3l0.1-0.5l-0.5,0l-0.5,0.9l0.3,2.1l0.9,0.1l2-0.2l1.8,0.9l1.8,0.6l1.2,0.1l3,0.5l0.5,0.3l0.1,0.3l-0.2,0.4
		l-2.1,1.6l-1.9,0.4l-3.9,0.1l-4.3,0l-0.7,0.2l-0.7,0.3l-1.1,0.3l-0.7,0l-0.5-0.1l-1.4-0.9l0.4-0.6l0.2-0.4l-0.1-0.3l-0.4-0.6
		l-0.2-0.4l0.1-1.2l0.4-0.9l0,0l4.2-5l2.9-3.4l3.6-4.3l0.2-0.2l0,0l0.3-1.2l0.8,0.7l0.2,4.1l0,0.6l2.9,1l2.1,0.6l0.4-0.2l1-0.8l4.1-1
		l0.4,0.3l0.5,0.5l0.5,0.4l1.9,0.3l0.6-0.6l0.3-0.4l0.4-0.3l0.7,0.1l0.5,0.4l0.7,0.3l0.7,0.2l0.6-0.2l0.9-1.2l1-1.3l0.3-0.2l0.4-0.2
		l1.4-0.6l0.6-0.2l3.2-0.3l2.2-0.3l3.2-0.8l0.4-0.3l0.2-0.3l0.1-0.4l-0.1-0.4l-0.2-0.4l-0.3-0.5l-0.4-0.5l-1.1-0.8l-0.3-0.3l-0.3-0.5
		l-0.8-1.5l0-0.4l0.4-0.4l0.3-0.3l0.2-0.3l0.2-0.5l-0.1-0.5l-0.2-0.3l-0.6-0.3l-0.4-0.3l-0.2-0.3l-0.1-0.4l0.2-1.7l0.8-1.1l0.6,0.1
		l1.2,0.5l0.5-0.1l0.5-0.5l0.4-0.3l0.7-0.4l0.5-0.2l3.5,0.3l0.5,0.2l0.1,0.7l1.7,0.9l1.1-0.3l0.5-0.3l0.3-0.4l0.3-0.5l0.8-0.6
		l0.6,0.2l0.4,0.4l0.6,0.3l1.1,0l0.9-0.1l2,0.5l0.3,0.3l0.3,0.4l4.2-1.6L607,84l0.1-0.7l0.2-0.4l0.2-0.3l0.3-0.4l0.6-0.7l2-1.8
		l0.6-0.3l0.8,0l1.9-0.5l0-3.1l-0.1-6.6L613.4,58l-0.1-4.6l0-1.6l0.3-0.8l1.1-2.5l1.9-4.4l0-2.5l0-0.8l1.2-2.9l0.3-0.6l0.6-0.8
		l1.1-1.5l0.2-1.4l0.2-1.1l0.2-1l0.5-2l1.1,0.1l-1,0.5l-0.2,0.4l-0.4,2.4l-0.1,1.7l0,0.3l-0.3,1.8l-0.2,0.6l-0.2,0.3l-0.5,0.6
		l-0.3,0.4l-0.4,0.7l-0.2,0.5l0,0.5l0,0.3l0.1,0.8l1.2,2.5l0.3,0.4l0.4,0.2l0.7,0l1.6,0.1l1.6,0.9l0.5,0.3l0.7,0.7l0.4,0.2l0.2,0.3
		l-0.2,1.5L625,49l0.3,1.5l1.5,3.2l0.2,0.4l2.8,4.6l0,0.4l0.3,2.2l0.4,1.8l3.4,0.4l0.7-0.2l0.3-0.3l1.7,0l3.2,1.1l0.5,0.3l0.4,0.3
		l0.5,0.2l0.7,0.1l4,0.5l0.8,0l0.6-0.1l0.8-0.2l0.6-0.2l0.8-0.2l0.7-0.1l0.9-0.1l0.8,0.1l3.1,0.5l0.6,0.3l0.9,0.7l1.3,2.7l0.3,0.7
		l-1.2,2.4l-0.1,0.4l-0.1,0.6l0,0.7l0,0.3l-0.1,0.6l-0.3,0.9l-1.6,2.2l-0.3,1.2l-0.5,1.6l-0.5,0.9l-2.5,2.5l-1.3,0.9l-1.1,0.7
		l-0.6,0.3l-1.2,0.5l-2-0.4l-1.8-2l0.3-2.4l1.1-0.4l0.5-0.1l0.9,0l0.6,0.2l0.6,0.9l0.5,1l0.2-0.6l-0.4-1.2l-0.3-0.5l-0.6-0.8
		l-0.5-0.1L645,80l-1,0.1l-0.5,0l-0.8-0.1l-0.5-0.3l0-1.8l0.5-0.4l4.4-0.8l0.6,0.2l0.3,0.3l0.4,0.4l0.8,1.3l0.2,0.4l0.4,0.5l0.7,0
		l0.1-0.1l-0.4-0.7l-0.7-1.7l-0.3-0.4l-1.2-0.6l-1.6,0.1l-1.1,0.2l-1.5-0.1l-1.7-0.4l-2-3.7l0-0.4l0-0.4l-0.1-0.4l-0.2-0.3l-0.2-0.3
		l-0.3-0.3l-5-2.8l-0.6-0.1l-1.2,0.7l-0.4,1.3l-0.1,1.4l2,3.6l2,3l1,1.2l0.6,0.2l2.3,1.5l0.2,0.8l0,0.4l-0.3,0.5l-0.4,0.3l-0.5,0.3
		l-0.5,0.3l-0.3,0.3l-0.4,1.2l0.8,3l0.5,0.4l-0.2-1.1l0-1.4l1.9-2.2l0.6-0.3l0.4,0.2l0.6,2l-0.1,0.5l-0.5,0.6l-0.3,1l0.4,0.7l0.6-0.4
		l0.2-0.5l0-0.3l0.2-0.4l2.8,1.4l0.2,0.4l-0.2,0.7l-0.6,0.4l-0.3,0.3l0.9-0.1l1.4-1.2l-0.4-0.4l-0.1-0.4l2.2-1.5l0.6-0.2l0.4,0.2
		l0.2,1.4l0,0.1l-0.5,0.4l-2.3,2.8l-1,1.9l-0.8,1.1l-0.4,0.3l-1.2,1.5l-0.1,0.3l0,0.4l-0.1,0.4l-0.9,1l-1.7,1.3l0.2-3.2l-0.3-1.1
		l0.1-0.8l1.1-1.4l1.7-1l0.3-0.3l-0.1-0.6l-1.2-0.2l-0.7,0.8l-0.6,0.6l-1-0.5l0.1-0.6l0-0.8l-0.5,0.7l-0.2,1l0.1,0.4l0.4,0.4l0.1,0.4
		l-1.1,1.2l-0.5-0.4l-0.2-0.2l-1.1-0.3l-0.8,0.6l-1.4,0.4l-1.8,0.1l-0.8-0.6l-1.9-2.8l1.2-2l0.5-1.7l-0.2-0.6l-1.5-1.7l-2-1.7
		l-0.5-0.2l-0.4-2.3l1.1-1.6l0.2-0.7l0-0.8l-0.1-1l-0.1-0.5l-0.1-0.4l-0.2-0.5l-1-2l-1.4,0.6l-0.3,0.4l0,0.6l0.1,0.3l0,0.4l-0.3,1.8
		l-0.5,2.1l-0.3,3l0.1,2.1l0.1,0.5l0.6,0.5l0.9,0.3l1.7,0.7l-1.5,1.8l-1-0.7l-0.3-0.4l0-0.4l-0.3-0.6l-0.4,0.3l0.5,1.5l0.3,0.3
		l0.2,1.2l0,2.3l1.9,1.5l0.3-0.3l-0.7-1l0.7-1.8l0.5,0l1.6,1.1l0.7,0.6l0.8,0.9l0.2,0.9l0.1,0.4l0.6,0.4l0.6,0.3l1.5-0.5l1.6-0.4l1,0
		l0.9,0.4l0.6,1.5l0.1,0.1l0.3,0.5l0.1,1.1l-0.7,1.6l-1.7,1.9l-1.5-1.9l-1.2-1.1l-0.2,1.7l-1.8,2.9l-0.6-0.7l0.1-1.3l0.4-0.4
		l-0.3-2.2l-0.7,0.3l-0.9,0.1l-5-2.2l-0.3-0.6l0.2-1.2l0.2-0.8l0.1-0.4l-0.5,0.4l-0.3,1.4l-0.1,0.4l0,0.4l0.2,1.1l0.4,0.4l1.2-0.2
		l0.5,0.1l2.1,1.3l0,0.5l-0.2,0.3l-0.5,0.1l-1,0.1l-0.2,0.4l0.2,0.3l1.4,1.6l0.8,0.8l0.9,0.5l0.5,0.2l-0.5,1.2L628,104l-1-0.7
		l-0.2-0.6l0.2-0.4l-0.4-0.2l-0.2,0.7l-0.4,0.3l-2.1,0.7l-0.7,0l-0.4-0.2l0.1-0.4l0.1-0.4l-1.2-0.4l-2.2,0.3l-1.5,0.9l-1.7,1.1
		l-0.6,0.2l-0.8-0.1l-0.3-0.4l0.4-0.4l0.8-0.2l2.6-1.7l0.1-0.4L618,101l1.1-1.7l0.1-0.3l-0.2-0.8l-0.3-0.3l-1-0.3l-1.1-0.7l-0.5-1
		l-0.5-1l0.1-0.5l1.6-0.1l1.1,0l1.4-1.1l0.3-0.6l-0.6-0.5l-3.2-0.8l-0.9,0.2l0.5,0.5l1.2,0.4l0.6,0l1,0.4l-0.7,1l-2,0l-3-1.1
		l-0.4-0.8l1.1-1.5l1.3,0l1-0.6l0.3-0.6l0.3-0.3l1.3-0.7l0.7-0.3l2.8,0.6l0.6,0.5l0.8,1.9l1.7,2.6l0.2-0.4l-0.4-1.1l-1.1-2.6
		l-0.5-0.8l-1.3-1.3l-2.6-0.3l-0.5,0.2l-1.2,1l-1.8,1.4l-2,0.3l-0.4-0.5l0.2-1.6l-0.2-0.4l-0.3-0.3l-0.4-0.2l-3,0.2l-1.4,0.6
		l-2.5,2.2l-0.7,0.8l-0.2,0.4l-0.1,0.6l0,0.7l-0.5,1.2l-1.3,2l-0.5,0.3l-0.5,0.6l0.1,0.6l0.4,1.2l0.5,0.4l0.4-0.2l0.2-0.6l-0.1-0.3
		l-0.4-0.6l0.4-1.3l0.7-1.2l1.6-1.8l2-2L607,90l0.4-1.6l0.4-0.3l1.1,0.2l1,0.8l0.1,1.3l-0.7,1l-0.7,0.4l-1,2.4l-2.1,2.9L605,98
		l0.1,0.6l2.2-0.8l0.3-0.5l0.1-0.6l0-0.4l-0.3-0.5l0.3-0.5l0.4-0.4l0.8-0.5l1.5,0.9l0.2,0.3l0.1,0.6l0.3,1l-0.3-1.7l-0.7-0.9
		l-0.3-0.5l0.3-0.5l0.6-0.2l0.5,0.5l0.4,1l0.5,0.9l0.5,0.7l0.6,0.2L616,97.1L616,97.1z M679.5,31.6l2.4,0l0.1,0.1l0,0.1l0,0.2
		l-0.1,0.2l-0.3,0.3L681,33l-0.2,0.3l-0.9,1.9l1-0.6l0.2-0.2l0.2-0.2l0.2-0.4l0.2-0.6l0.5-0.2l0.4,0.3l0.2,0.5l0.5,2.2l0.2,1.5l0,0.3
		l-0.1,0.3l-1.9,5.3l-0.4,1l-0.8,1.5l-0.6,0.1l-0.3-0.1l-0.5-0.4l-0.3-0.1l-1.5,0.1l0,0.1l0.8,1.1l1.5,0.1l0.5,0.3l1.1,4.9l0.1,1.7
		l0,0.5l-0.1,0.5l-0.2,0.4l-0.5,0.2l-0.8,0.1l-0.4-0.2l-0.4-0.3l-1.3-0.7l-0.3-0.1l-0.1,0.2l1.6,1.2l0.8,0.4l0.4,0.1l0.3,0l0.2,0.4
		l-0.1,0.5l-0.1,2.8l0.8,1.5l0.1,0.2l0,0.2l-0.1,0.6l-0.2,0.3l-0.3,0.2l-0.9-0.7l-0.2-0.2l-0.1-0.2l-0.1-0.6l-0.2-0.4l-0.5-0.9
		l-0.8-1.2l-0.2-0.3l-1.2-0.8l-0.3-0.1l-0.1,0.1l0,0.3l0.1,0.9l0.1,0.2l1.1,0.9l0.2-0.2l0.2-0.4l0.2,0.1l0.4,0.7l0.5,1.3l0.3,1l0,1.4
		l-0.8,0.3l-0.1-0.2l0-0.6l-0.4-1.1l-0.2-0.1l-0.2,0.2l0,0.2l0,0.1l0.3,0.9l0.8,0.9l1.4,1.1l0.1,0.4l-0.2,0.1l-0.3,0l-0.3-0.1
		l-0.2-0.1l-0.3-0.2l-0.2-0.1l-0.3-0.1l-1.6-0.4l-0.5,0l-0.1,0.6l0.3,0.4l0.3-0.1l0.3,0.1l0.3,0.2l0.9,1.5l0.1,0.2l-0.6,1.3l-0.1,0.3
		l-0.2,0.1l-0.3,0l-0.2-0.2l0-0.2l-0.8-0.2l-0.9,0.1l-1.3,1l0,0.3l0.8,0.7l2.8,1l0.5,0.1l0,0.2l-0.1,0.2l-1.7,2.3l-0.2,0.2l0,0.4
		l0.2,0.4l0.5,0.6l0.4,0.3l0.1,0.3l0.1,1.4l0,0.2l-0.1,0.3l-0.2,0.2l-0.5,0.2l-1.4,0.3l-1.4,0.1l-1.1-0.1l-0.2-0.2l0-0.2l0.3-0.8
		l0.7-0.7l0.5-0.2l0.3-0.1l0.3-0.2l0.2-0.3l0.4-1.1l0.3-0.9l0-0.2l-0.7-1.3l-1.9,1.7l-0.2,0.3l0,0.2l0.3,0.5l0,0.2l-0.4,0.6l-0.2,0.2
		l-0.8,0.1l-0.5-0.8l0-0.4l0-0.3l0-0.2l0.3-0.3l0.6-0.2l1.4-1l0.2-0.2l0.1-0.2l0.1-0.5l-0.4-1l-0.2-0.1l-0.2,0.3l-0.1,0.8l-0.1,0.2
		l-0.2,0.2l-0.4,0.2l-0.5,0.2l-0.3-0.2l0-0.2l0.9-2l-0.5-1l-0.4,0.7l-0.4-0.3l-0.4-0.5l0-0.2l1.4-2.4l0.9-3.1l-1.3-0.5l-0.3,0l-0.3,0
		l-0.3,0l-0.6,0.1l-0.3-0.1l-0.4-0.6l-0.2-0.6l0-0.6l0-0.5l0.1-1.5l0-0.2l0.2-0.6l0.2-0.1l1.3,0.8l1.4,0.6l1.5,0.3l2,0.3l-0.6-0.5
		l0.9-1.1l-1.2,0.7L674,61l-0.5,0.1l-0.4-0.1l-1.5-0.4l-0.2-0.1l-0.5-0.3l-0.2-0.2l-0.5-0.5l0-0.2l0.4-0.2l0.3-0.2l1.3-1.5l0.2-0.5
		l0.1-0.2l0.1-0.2l0.6-0.8l0.2-0.2l0.2-0.1l0.3-0.2l0.3-0.2l0.1-0.3l0-0.3l-0.7-1.6l-1,1.3l-0.2,0.3l-0.3,0.6l-0.2,0.4l-0.4,0.7
		l-0.1,0.3l-0.2,0.3l-0.4,0.6l-0.2,0.2l-1.3,0.7l-0.5-0.1l-0.4,0.1l-0.4,0.2l-0.2,0.2l-0.3,0.2l-0.2,0.4l-0.3,0.8l0,0.3l-0.1,0.6
		l0.1,1.2l0,0.5l-0.1,0.3l-0.1,0.2l-0.3,0.3l-0.3,0.2l-2.3,0.6l-0.2-0.5l0.3-0.4l0.8-1.1l1-1.9l2.4-4.3l2.5-2.7l0.1-0.3l-1.1-1
		l-0.3,0l-0.2,0.2l-0.2,1l-0.1,0.3l-0.3,0.3l-0.3,0.1l-0.3,0l-0.9-0.9l-0.5-0.5l-0.2-0.2l0-0.2l0-0.2l0.5-0.7l0.7-0.5l3.2-1.5
		l2.7-1.1l0.4-0.4l-0.1-0.3l-1.8-0.2l-2.3,1l-1.2,0.6l-0.2,0.2l-0.3,0.3l-0.6-0.2l-0.1-0.1l0-0.3l0.2-0.6l0.8-1.6l0.3-0.3l0.5-0.4
		l0.8-3.5l1.1-1.9l0.1-0.2l0.2-0.1l0.3-0.1l0.3,0l1.3,1l0.1,0.1l0.3,0.5l0.2,0.2l1.2,0.6l0.4,0.2l1.3-0.5l0.2-0.2l-0.9-0.1l-1.3-0.5
		l-0.8-0.4l-0.4-0.2l-0.2-0.2l-0.3-0.4l-0.1-0.3l0.1-0.2l0.2-0.1l0.5-0.2l0.6-0.2l-0.1-0.1l-0.6-0.3l-0.8,0.2l-0.3,0.2l-0.5,0
		l-0.4-0.1l-0.4-0.2l-0.1-0.2l0-0.6l0-0.2l0.2-0.4l0.5-0.5l0-0.2l-0.2-0.2l-0.9,0l-0.2,0.2l-0.1,0.2l-0.6,0.9l-1.5,2.7l-0.9,2
		l-0.1,0.4l-0.5,1.2l-0.1,0.2l-0.2,0.2L666,45l-0.6,1.6l-0.1,0.4l0,0.3l0,0.3l0,0.3l-0.1,0.4l-0.5,1.3l-1.6,2.3l-0.3,0.3l-0.3,0.1
		l-3.7,1.2l-2.1,0.4l-0.6,0.1l-0.4,0l-0.5,0l-0.5-0.3l-0.2-0.2l-2.1-3.2l-0.1-0.2l0.1-0.4l0-0.3l0-0.2l0-0.3l-0.3-0.7l-3.3-5.7
		l-0.1-0.2l-0.4-0.2l-0.5-0.1l-0.2,0l-2.4,0.3l-0.3,0.1l-0.3,0.3l-0.2,2.7l0,0.5l0.7,4.1l0,0.1l0.2,0.6l0.3,0.5l0.5,0.6l0.2,0.2
		l0.6,0.3l0.2,0.3l0.9,1.8l0,0.2l0,0.2l-0.1,0.2l-0.2,0.3l-2.1,2.6l-0.2,0.1l-1.8,0.4l-1.2,0.1l-0.3,0l-4.3-0.4l-0.3-0.1l-0.2-0.1
		l-0.3-0.2l-1.3-1.1l0.2-0.2l0.6,0.2l0.4,0l1.1-0.3l0.1-0.2l-0.5-1.6l-0.1-0.2l-0.2-0.3l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.1
		l-1.4-0.4l-0.3,0l-0.4,0l-0.2,0.1l-0.2,1.5l-1.1,0.7l-0.3,0l-0.3,0.1l-0.3,0.3l-0.2,0.3l-0.1,0.4l0,0.2l0,0.3l0.1,0.4l0,0.2
		l-0.2,0.1l-0.5-0.3l-0.2-0.4l-0.5-2.6l0-0.3l0.1-0.4l0.2-0.4l0.2-0.2l0.3-0.2l0.5-0.2l0.7-0.5l0.3-0.2l0.3-0.4l0.1-0.4l0-0.3l0-0.3
		l-0.1-0.3L631,48l-0.3-0.3l-0.4-0.3l-2.2-1.3l-0.4-0.1l-0.3,0l-1.9,0.1l-0.4-0.3l-0.3-0.3L624,44l-0.2-0.5l1-0.8l0.2-0.1l0.2-0.1
		l0.3-0.1l0.3,0l0.3-0.1l2.2-1.6l0.2-0.2l0.4-0.4l0.3-0.4l0.1-0.4l0-0.2l-0.2-0.4l-0.8-1.6l-1.2-1.8l-0.1-0.2l-0.3-0.2l0.7-1.7
		L628,32l0.2,0l3.4,0l11.4-0.1l1.5,0l4.1,0l7.9-0.1l3.8,0l8.2-0.1l0.5,0l4.7,0L679.5,31.6L679.5,31.6z M570.2,120.1l0,0.2l-0.6,1.1
		l-0.4,0.3l-0.8-0.4l-0.4-0.4l-0.6-0.9l-0.2-0.4l0.2-0.3l0.3-0.5l-0.9,0.7l-0.1,0.4l0.2,0.4l1,1.2l0.1,0.5l-0.6,0.4l-1-0.1l-2.1-0.3
		l-2.2,0.5l-3.3-0.5l-0.6-0.6l-0.7-0.8l-2.6-1.8l0.2,0.6l1.2,0.9l1.9,1.6l0.8,1.1l-0.4,0.2l-0.9,0.1l0.6,0.5l0.6,0l1.1,0l0.8,0
		l0.6-0.2l0.5-0.4l2.9,0.3l2,0.4l-0.1,0.5l-1,1.3l-2.1,1.1l-2.6,1.2l-1.3-0.3l-1.5,0.3l-0.9,0.5l-0.6,0.6l0.2,0.5l0.3,0.4l-1.2,0.4
		l-0.7,0.1l-1.2,0l-0.7-0.5l-0.1-0.3l0.1-0.3l1.3-0.8l0.6-0.2l1.1-0.5l1.5-1.2l0.3-0.4l0-0.1l-0.2,0l-0.9,0.5l-0.7,0.5l-0.3,0.3
		l-0.5,0.3l-1.4,0.6l-1,0l-0.2-0.7l0.4-0.3l1.3-0.8l0.1-0.4l-1.7-1.2l-0.4-0.1l-0.9,0.3l0.4-0.5l1.4-1.2l1.1-0.8l-1-0.4l-4.2-0.8
		l-1,0.9l-0.5,0.5l-0.2,0.3l-0.5,0.3l-1.7,0.8l-1.5,0.5l-1-0.2l-0.8-1l-1.4-1.9l-0.4-1.4l0.6-2.9l0.9-0.3l3.2-0.4l0.9-0.4l0.4-0.1
		l2.7,0.1l2.3-0.5l0.5-0.1l1.8,0l2.7,0.3l1,0.3l0.6,0.3l0.8,0.1l1-0.3l0.8,0.7l1.5,0.7l0.6,0l-1-0.9l-0.4,0.1l-0.5,0l-0.2-0.9
		l0.4-0.4l0.7-0.3l1.4-0.1l4.3,0.7l0.4,0.2l1.1,0.9l1.1,1.2l0.1,0.4L570.2,120.1L570.2,120.1z M692.5,30.5l0,0.3l-0.1,0.3l-0.4,0.3
		l-0.7,0.3l-0.3,0.4l0,0.4l0.4,0.5l2.1-2l-0.2-0.8l-0.3-0.4l-0.2-0.3l-0.1-0.4l0.1-0.7l0.4-1l1.2-2.6l0.5-0.3l0.7,0.1l0.5,0.2
		l0.2-0.6l-0.1-0.8l1.3,0.6l1.5-0.9l0.2-0.4l0.1-0.7l2,0.1l1,1l0.2,0.2l0,0.1l-0.1,0.4l-0.4,0.2l-0.7,0.5l-1.3,1.1l-1.2,0.6l-0.9-0.2
		l-1.3,1.6l-0.1,0.3l0.6,0.5l0.1,0.8l-0.9,1l0,1l0,1.7l0.7,0.6l0.3,0.8l0,0.4l-0.5,0.6l-0.8,0.6l-0.8,0.4l-0.7-0.5l-0.3-0.9l-0.1-0.4
		l0.1-0.4l-0.1-0.5l-1.3-0.2l0.7,1.8l-0.5,0.2l-1.4-0.3l-1.3-0.4l-0.6-0.6l-0.5-0.1l1.8,2.2l0.2,0.9l-0.5,1.6l-0.2,0.4l-0.4,0.4
		l-0.5,0.3l-1.6,0.4l0.4,1.8l0.1,0.4l0.2,0.3l-0.1-0.9l0.1-1l1.5-0.4L691,42l1,0.2l1.9-1.2l1.5-0.1l0.3-1.3l0.1-1l1.5,0.1l0.3,0.2
		l-0.5,2.2l0.3,1.5l-2.2,1l-0.9-0.4l-0.2-0.7l-0.2-0.3l-0.5,1l0,0.4l1,1.1l-1.1,0.6l-0.6,1.3l-0.8-0.1l-0.8-0.4l-0.9-1.1l-0.1-0.4
		l-0.2-0.5l-0.5-0.3l0,0.6l0.4,0.6l1,1.3l0.6,2.1l0,0.4l-0.8,0.9l-0.1-0.8l-0.3-0.3l-0.9,0.9l-0.1,1.6l-0.3,0.2l-0.7-0.3l-1.3-1.1
		l-0.3-0.5l-0.4-1.5l-0.3-2.1l-0.8-4l-0.7-2.4l-0.3-2.9l0.1-2.3l-0.1-0.3l-0.8-1l-0.2-1.1l-0.2-2.1l0.6-1.5l1.2-0.9l1-0.2l0.7,0.2
		l1.5,3.2l0.4,0.2l0.2,0.8l-0.5,1.8l-0.5,0.7l-0.5,0.5l-0.4,0.5l-0.2,0.3l-1.1,1.6l0,0.3l0.6,0.3l1.5-1.4l0.6-1l1-1.7l0.1-2.6l0-2.1
		l0.1-0.9l1-1.5l1.1-1l0.5,0.1l0.7,4.2L692.5,30.5L692.5,30.5z M656.9,98.4l-0.5-0.7l-2.3-0.1l0.1,1.9l0.5,0.6l0.3,0.5l0.1,0.4l0,1.2
		l-0.5-0.6l-0.2-0.4l-0.3-0.3l-1,0.1l0,0.4l0.1,0.6l-0.1,0.4l-0.3,0.3l-1,0l-0.5-0.2l-0.2-0.2l-0.1-0.6l0.3-0.4l0-0.3l-0.2-0.3
		l-0.4-0.4l0-0.3l0.6-1.1l0.4-0.3l0.5-0.2l0.6-0.4l0.3-0.4l0.4-1.2l0-0.4l-0.1-1.3l-0.2-1.3l-0.6,0l0,0.4l0.3,0.3l0.1,1l-0.1,0.9
		l-0.1,0.5l-0.3,1L652,98l-2.8,1.8l-0.9,0.3l-0.1-0.6l0.2-0.5l0.4-0.8l0.5-2.6L648,96l-0.9,0.6l-0.5-1.4l0.1-1.1l0.5-1.3l2.6-0.6
		l1.1,0.2l0.9,0.2l1.3-0.5l-2.5-0.8l-0.8-0.1l-0.9,0.4l-0.9,0.4l0.4-1l0.9-0.8l2-0.9l-1-1.2l0.2-1.5l0.4-0.7l0.9-0.6l0.7,0.1l0,0.4
		l0.2,0.4l0.5,0.3l1.6-0.6l-0.7-0.5l-0.6-0.5l0.6-1.6l0.3-0.3l1.8-1.4l0.4,0.5l0.1,0.2l1,0.3l0.3,0.5l0,0.4l-0.2,1.1l0.8,0.9l1.7,0
		l0.2-0.3l0.3-0.6l0.1-0.5l1.1,0l0.2,1.5l1.3,1.5l0,0.4l-0.1,0.3l-0.7,0.6l0.7,1.2l1.1,1.6l0,0.7l-0.8,1.7l-0.5,0.4l-0.4-0.3l0-1.6
		l-1.1-1.6l-4.1-0.7l-0.5,1.1l0.3,0.4l0.8,0.9l0.2,0.4l-0.1,1.3l-0.5,0.1l-0.6,0.1l-0.2,0.5l2.5,0.6l0.6,0l1.7,0.4l-0.6,1.3l-1.2,0.5
		l-1.3,0.5l-0.9,0.3L656.9,98.4L656.9,98.4z M682.3,23.5l0-1.4l0.2-1.5l0.1-1.2l0.1-2l-0.1-0.9l0-0.3l0-1.8l0.1-0.6l0.2-1l0.1-0.5
		l0.2-0.5l0.2-0.3l0.3-0.6l0.2-0.3l2.4-1.6l1.6,0l1.1,0.4l0.4,0.2l0.2,0.1l2.5,1.8l0.1,0.1l0.1,0.6l0,0.2l-0.4,1.4l-0.1,0.2l-0.2,0.3
		l-0.2,0.2l-0.2,0.1l-0.4,0l-0.4-0.3l-0.3-0.2l-0.3,0l-0.2,0.1l-0.3,0.3l-0.2,0.3l-0.1,0.4l-0.1,0.4l0,0.3l0.1,0.3l0.2,0.5l0.2,0.2
		l0.2,0.1l0.4,0.1l0.6,0.1l0.6,0l0.2,0.2l0,0.2l-0.1,2l-0.1,0.3l-0.5,0.8l-0.3-0.2l0-0.2l-0.4,0l-0.8,0.4l0.1,0.3l1,1.9l0.3,0.5
		l0,0.1l0,0.3l-0.1,0.2l-0.1,0.2l-0.3,0.3l-0.2,0.1l-2.7,0.6l-0.4-0.1l-2.3,0.3l0.1,0.3l0.1,1.3l-0.5,0.4l-0.9,0.2l-0.3-0.2l-0.1-0.2
		l-0.3-1.9L682.3,23.5L682.3,23.5z M643.6,99.4l-0.2-0.9l0.1-0.2l0.7-1l1.1-1.6l0.3-0.2l0.3,0.1l0.1,0.2l0,0.1l-0.1,1.6l-0.9,3.6
		l-0.6,0.1l-0.3,0.1l-0.4,1l-0.9,0.4l-1.6,1.7l0.1,0.2l-1.4,1.4l-1.1,0.9l0.3,1.2l0.3,0.6l0.4,0.7l-0.1,3.3l-0.2,0.3l-1.4,0.4
		l-1.3,1.1l0,0.3l-0.2,0.9l-0.9,1.2l-0.3,0.1l-0.4-0.1l-1.1-0.3l-0.6,0.4l-0.1,0.1l-0.1,0.4l-0.3,0.7l-0.1,0.2l-1,0.7l-0.9-0.6l0-0.2
		l0.4-0.7l2-2.1l0.9-0.8l1.3-0.6l0.7-0.6l0.3-1.6l-0.3-0.2l-0.2-0.6l-0.2-1l0.6-1.5l1-1.8l0.8-0.9l0.4-0.6l0.5-1.1l0-0.9l-0.5,0
		l0.2-0.7l0.8-0.6l0.2-0.1l1-0.3l0.3,0.1l0.3,0.2l0.3,0.1l1.4-0.5l0.2-0.2l0.1-0.2l-1-0.5L642,101l-0.5,0.1l-0.8,0.1l-0.3-0.2l0-0.2
		l0.1-0.3l0.2-0.4l1.9-1.6l0.3-0.1L643.6,99.4L643.6,99.4z M661,69.5l1.5-0.4l0.2-1.2l-0.6-1.3l0.7-1l0.3-0.1l1.8-0.3l2.2-0.1
		l2.7,1.7l0.2,0.2l0,0.3l-0.2,0.3l-0.7,0.5l-0.2,0.8l-0.6,1.3l-2.5,1.4l-0.4,0.1l-0.4-0.1l-0.2-0.2l0-0.4l-0.1-0.2l-0.2-0.1l-0.9,0.6
		l1,1.2l1.6,0.5l0.3,0.1l0.5,0l-0.3,1.4l-0.2,0.3l-0.8,0.3l-0.5,0.2l-0.5,0.1l-0.3-0.1l-1-0.8l-2.8,0.4l-0.5-0.2l-0.4-0.3l-0.2-0.2
		l-0.1-0.6l0-1.4l0.6-1.3l0.6-1.1l0.1-0.1L661,69.5L661,69.5z M666.1,58.6l0,0.1l-0.5,1.2l-0.3,0.6l-0.3,0.5l-0.1,0.2l-1.2,1.3
		l-0.3,0.3l-1.6,0.9l-2.4,0.3l-0.3,0l-1.4,0l-0.2-0.1l-0.6-0.8l-0.3-0.7l0-0.2l0.4-1.2l0.3-0.3l0.1-0.2l0-0.2l-0.2-0.3l-0.3-0.2
		l-0.3,0l-0.3,0.1l-0.3,0.3l-0.1,0.2l-0.3,0.6l-0.2,0.1l-0.3,0.1l-1.6,0.1l-0.3-0.1l-0.1-0.4l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.4-0.3
		l-0.3-0.2l-0.2-0.1l-0.4-0.1l-0.5-0.1l-0.3-0.1l-0.2-0.1L650,59l-0.1-0.7l0.2-0.2l1.8,0l0.3,0.1l0.3,0.1l0.3,0l0.5,0l0.3,0l0.3,0
		l3.3-0.7l2.1-0.8l1.4-0.5l0.4,0l0.3,0.3l0.1,0.3l0,0.5l0,0.2l-0.1,0.3l-0.1,0.2l-0.3,0.4l-0.4,0.4l-0.6,0.4l-0.2,0.2l-1.3,1.4
		l0.3,0.1l0.3,0l0.8-0.2l0.2-0.1l0.2-0.1l0.4-0.3l0.2-0.3l0.2-0.1l0.5-0.3l2-0.6l0.4-0.1l1.8-0.3L666.1,58.6L666.1,58.6z M659.4,75
		l0.7,0.7l0.3-0.2l0.3-0.3l2-0.5l0.4,0l1.2,0.8l0.3,0.3l0.1,0.2l0,0.2l-0.2,0.5l-0.1,0.2l-0.2,0.2l-0.3,0.2l-1.9,1.2l-0.4,0l-0.5-0.1
		l-0.2,0.1l0,0.2l0.4,0.2l0.3,0L662,79l1.7-0.5l0.6-0.5l0.1-0.2l0.2-0.3l1.7-1.4l0.4,0l0.3,0.1l1.9,1.1l0.1,0.1l0,0.2l-0.3,1.2
		l-0.5,0.3l-1.3-0.2l-2.2,2.3l-1.4,1.3l-0.2,0.2l-0.3,0.1l-0.2,0.1l-2.6,0.4l-0.5,0.1l-0.4-0.1l-0.1-0.6l0-0.1l0-0.2l0.4-0.9l0.2-0.2
		l0.5-0.2l0-0.6l-1.8-0.7l-0.7-0.2l-0.2-0.4l-0.1-0.3l1.1-3.2l0.5-1l0.4,0.1L659.4,75L659.4,75z M575.7,124.2l0.5,0.3l2.4,1.7
		l0.2,0.2l0.1,0.3l-0.1,1l-0.1,0.2l-0.2,0.1l-0.3,0l-0.4-0.2l-0.3-0.5l-0.5-0.4l-0.9-0.6l-0.2,0.2l0.3,1l0.5,0.7l0.6,1.4l0,0.2
		l-0.1,0.2l-0.4,0l-1.6-0.2l-1.2-0.3l-1.1-0.4l-0.3-0.1l-0.2-0.2l-0.1-0.2l0-0.3l0.1-0.2l0.4-1l0.2-0.1l0.2,0.1l0.1,0.1l0.1,0.4
		l0,0.6l0.2,0.1l0.4-0.2l0.2-0.2l0.3-0.6l0.3-1.1l-0.1-0.8l-1.1,0.1l-0.7,0.2l-0.3,1l-0.7,1.1l-0.9,1.1l-0.8,0.3l-0.9-1.1l0-0.2
		l0.5-2.3l0.1-0.2l2.3-1.9l0.7,0.7l0.3,0.2l0.2,0.1l0-0.6l-0.6-2.3l-0.6-0.2l-0.3,0.1l-0.3-0.6l0.2-0.2l0.5-0.1l0.8,0l0.7,0.2
		l1.6,2.7l0.1,0.2L575.7,124.2L575.7,124.2z M593.8,119.2l-1.1,2.4l-1.9,1.1l-0.2,0.1l-0.4,0l-0.4-0.1l-1-0.4l-0.1-0.2l0.1-0.8
		l0.4-1.7l0.3-0.8l-0.6-1.1l-1.8-0.1l-0.4,0l-0.3,0.1l-0.4,0.4l-0.1,0.2l-0.1,0.2l-0.2,0.1l-0.2-0.1l-0.2-0.3l0.1-0.5l0.4-1l0.6-0.9
		l1.1-1.2l0.3-0.1l0.4-0.1l3.7-0.4l1.3,0.3l0.2,0.1l-0.1,0.2l-0.4,0.1l-0.8,0.2l-0.6,0.4l-0.1,0.2l0.1,0.2l1,1.6l0.9,0.3l0.2,0.1
		L593.8,119.2L593.8,119.2L593.8,119.2z M682.5,56l0-0.4l0.1-0.1l0.9,1.8l0.1,0.6l0.2,0.3l0.4,0l0.4-0.1l0.2,0.1l0.3,1.6l0,0.5
		l-0.1,0.4l-0.2,0.3l0.1,0.2l0.4,0.2l0.5-1.1l0.3-2.3l-0.3-1.8l0-0.2l0-0.7l0.1-0.1l0.5-0.1l1.5-0.2l0.4,0.4l0,0.4l-0.1,0.2l-0.2,0.2
		l-0.4,0.1l-0.4,0.4l-0.1,0.3l-0.1,0.2l0.4,0.5l0.6,0l0.2-0.1l0.2-0.4l0-0.3l0.1-0.2l0.2-0.1l0.4,0.1l1,2.1l0,0.3l-0.2,0.1l-0.3,0.1
		l-0.5,0l-0.6,0l-0.6-0.2l-1,0.8l1.1,1.3l-0.1,0.3l-1.1,3.1l-0.8,1l-1.9,0.1l-0.2-0.2l-0.1-0.2l-0.4-1.6l0.3-1.2l0.2-0.6l0.3-0.7
		l0-1.8l-0.1-0.2l-0.3,0.8l-0.3-0.1l-0.8-1.4l-0.1-0.2l-0.1-0.9L682.5,56L682.5,56z M587.9,112l-1,0.4l-0.2,0.2l-0.2,0.3l0.3,0.6
		l0.2,0.1l-0.5,1.2l-1.1,1.4l-0.2,0.1l-1.5,0.7l-0.3,0.1l-0.4,0.1l-0.3-0.1l-1.1-1.1l-1-1l-0.4,1.1l0,0.2l0.2,0.5l0,0.2l-0.8,1.5
		l-0.8-0.8l-0.1-0.2l-0.3-0.5l-0.1-0.3l0-0.2l1.6-2.4l2.9-1.3l1.3-0.5l2-0.8l0.4-0.1l0.5,0l0.7,0.2l0.1,0.1L587.9,112L587.9,112z
		 M686,49.6l-0.7-0.1l-0.3,0l-0.5,0.3l-0.1,0.4l-0.4,0.1l-1.6-0.3l-0.1-0.1l-0.3-0.8l0-0.1l-0.3-1.6l0-0.8l0.8-4l0.1-0.4l0.7-2.4
		l0.5-0.5l0.2,0.2l0,0.5l0.7,2.7l0.4,1.4l0.4,2.1l0.3,1.6L686,49.6L686,49.6z M630.9,107.3l0,0.1l-0.1,0.2l-2.1,1.9l-0.3,0.1l-0.2,0
		l-0.9-0.3l-0.2-0.1l-1-0.8l-2.2-2.2l-0.6-0.9l0-0.2l0.1-0.2l0.1-0.2l0.3-0.2l1.3-0.6l2.9,0.8l1.7,0.8l0.2,0.1l0.3,0.2l0.1,0.2
		l-0.1,0.2l0,0.1l0.3,0.5L630.9,107.3L630.9,107.3z M602.2,122.9l0,0.1l-0.2,0.3l-0.6-0.1l-0.8-1l-0.3-0.7l-0.2-0.3l-1.2-1l-0.3-0.1
		l0,0.2l0.9,1.5l0.2,0.3l-0.1,0.3l-0.3,0.2l-0.3,0.1l-0.3,0l-0.2-0.1l-2.5-2.2l-0.3-1.1l-0.1-1.4l0.8-0.6l0.2-0.1l1-0.4l1.9,0.1
		l0.3,0.2l0.7,0.6l0.1,0.1l0,0.3l-0.1,0.2l-1.7,0.5l0.3,0.6l0.7,0.3l1.3,0.6l0.1,0.2l0.1,0.6l0,0.5l0.1,0.8l0.1,0.2L602.2,122.9
		L602.2,122.9z M586.7,127.9l-0.4,1.4l-0.1,0.3l-0.2,0.2l-2.3,0.9l-0.3-0.1l-0.7-0.6l-0.3-0.5l0-0.2l0-0.2l0.2-0.4l-0.6-0.1l-1.1,0.5
		l-0.3,0.4l-0.3,0.5l-0.3,0l-0.6-0.7l0-0.3l0.6-2.9l0.1-1.2l0.1-0.1l0.3,0.1l1.9,1.4l0.1,0.2l0,0.2l-0.1,0.3l-0.1,0.2l-0.2,0.3l0.6,0
		l1.4-0.1l1.6-0.1l0.7,0.2l0.2,0.2l0.1,0.2L586.7,127.9L586.7,127.9z M567,126l-0.4,0.2l-1,0.8l-0.1,0.3l0.7-0.1l0.4-0.1l0.2,0
		l0.1,0.2l-0.1,0.3l-0.5,0.5l-0.3,0.3l-0.7,0.4l-0.3,0l-0.3-0.1l-0.6,0.8l1.5,1.6l-0.1,0.3l-0.9,0.4l-0.2,0.1l-2.7,0.2l-0.8-0.6
		l-0.3-2.5l0-0.2l0.1-0.2l0.2-0.2l0.7-0.5l3.2-1.6l0.2-0.1l1.6-0.3L567,126L567,126z M616.9,122.5l-0.5,0.3l-2.9,1.1l-3.4-1.2
		l-0.3-0.2l0.5-0.4l0.2-0.1l0.3,0.2l0.2,0l0.1-0.4l-0.1-0.5l-0.1-0.3l-0.4-0.3l-2.8,0.5l-1.1,0.4l-0.2,0.4l-0.2,0.2l-0.2,0l-0.3-0.2
		l-0.2-0.3l-0.1-0.2l-0.1-0.3l0.3-0.9l0.3-0.2l0.2-0.1l2-0.2l0.7-0.1l1,0.2l2.8,0.7l2.3,0.7l1.3,0.7l0.3,0.2l0.1,0.2L616.9,122.5
		L616.9,122.5z M549,127.4l-2.4,0.8l-0.3,0.1l0.7,1.4l0.9,0.4l0.2,0.1l0.2,0.2l0,0.3l-1.5,2l-0.2,0.1l-0.2,0.1L546,133l-0.3,0l-0.3,0
		l-0.3,0l-0.7-0.2l-0.2-0.2l-0.4-1.1l0.1-2.5l0.1-0.2l0.2-0.5l0.1-0.2l0.4-0.3l3.1-1.2l0.3,0l0.6,0.5L549,127.4L549,127.4L549,127.4z
		 M553.2,123l-1.6,1.3l-0.7,2.1l-0.4,0.6l-0.4,0l-0.3-0.1l-0.7-0.4l-0.3-0.2l-1.2-0.9l0.1-2.1l0.3-0.4l0.9-0.8l0.8-0.1l0.3,0l3.6,0.5
		l-0.2,0.2L553.2,123L553.2,123z M605.8,127.4l-0.3,0.1l-1.8,0l-0.6-0.6l-1-0.6l-1.1-0.1l-1.6,0l-0.4,0l-0.3,0l-0.3-0.1l-0.2-0.1
		l-0.6-0.7l-0.2-0.2l0-0.2l1.6-1.8l0.7-0.1l0.7,0l0.3,0.1l0.2,0.2l0,0.2l-0.2,0.2l0,0.3l0.1,0.1l2,0.9l0.4-0.1l0.4,0l0.4,0l0.3,0.1
		l0.4,0.2l1.3,1.9l0.1,0.2L605.8,127.4L605.8,127.4z M623.1,110.7l-0.5,0.6l-1,0.5l-1.8,0.1l-0.2-0.1l-0.2-0.2l-0.1-0.2l0-0.3
		l0.2-0.3l0.2-0.2l0.2-0.4l-0.1-0.2l-1.2,0.6l-0.2,0.2l-0.3,0.4l-0.4,1.2l0.1,0.8l0.1,0.2l0,0.3l-0.1,0.3l-0.3,0.4l-0.3,0l-0.9-0.9
		l-0.1-0.2l-0.1-0.3l0-0.1l0.1-0.2l0.1-0.2l1-1.1l1-1l0.7-0.6l2.6-2.2l0.3,0l0.5,0.6l1,1.8l0,0.5L623.1,110.7L623.1,110.7z
		 M553.2,131l0,0.1l-0.9,1.6l-0.2,0.2L552,133l-1.6-0.8l-0.5-0.8l-0.1-0.2l0.1-0.3l-0.7-0.7l-1.1-0.4l-0.9-0.7l-0.2-0.2l-0.1-0.2
		l0.1-0.2l0.5-0.2l1.5-0.6l0.3-0.1l0.6,0.3l0.4,0.2l0.2,0.1l2.2,2L553.2,131L553.2,131z M588.9,118.9l-0.1,1.1l-0.2,0.6l-0.5,1.5
		l-0.3,0.2l-1.6,0.4l-0.8-0.1l-0.3-0.1l-1.1-0.8l-0.4-1l0.1-0.2l0.8-0.9l2.6-1.2l0.3,0l0.8,0.1l0.5,0.2L588.9,118.9L588.9,118.9z
		 M612.9,111l-0.2,1l-1.2,2l-1,0.8l-3.1,1.1l0.6-1.8l2.3-2.9l0.8-1.2l0.3,0l1.3,0.6l0.2,0.1L612.9,111z M684,66.1l0,0.2l-0.5,1
		l-0.7,0.8l1.1,1.7l0.1,0.2l-0.3,1l-1.5,0.7l-1.4-0.7l0.1-0.7l0.4-2.2l0.3-0.9l0.3-0.4l0.2-0.5l-0.1-0.3l-0.2-0.3l-0.3-0.2l0-0.2
		l0.5-0.7l1.2-0.5l0.1,0.3l0,0.5L684,66.1L684,66.1z M619.1,104.7l-0.2,0.1l-0.7,0.4l-1,0.7l-1,1l-0.2,0.3l-0.6,0.9l-1.1,1.8
		l-0.1,0.2l-0.2,0.3l-0.8,0l-0.9-0.2l-0.4-0.5l0-0.2l0.2-0.3l2.2-2.4l0.7-0.7l0.5-0.5l0.3-0.1l1.7-0.7l1.3-0.3l0.4,0l0.4,0.1l0,0.2
		L619.1,104.7L619.1,104.7z M609.4,109.1l-0.1,0.3l-0.1,0.2l-1.4,2l-0.3,0.3l-0.3,0.2l-0.4,0l-0.2-0.1l-0.1-0.7l0-0.6l-1.3-0.7
		l-1.4,0.2l-0.2-0.1l-0.1-0.1l0-0.2l0.2-0.2l4.8-1.9l0.4,0l0.2,0.2l0.1,0.2l0.2,0.4l0.1,0.3l0,0.1L609.4,109.1z M704.2,12.9l-0.8,0
		l-0.1,0.1l-0.2,0.8l0.2,0.8l-0.5,1.3l-0.6,0.1l-1.2-0.2l-1.8-1.6l-0.4-0.8l0-0.2l3.7-1.3l1-0.2l0.3,0.1L704,12l0.1,0.3L704.2,12.9
		L704.2,12.9z M617.4,121L617.4,121l-0.6,0.4l-5-1.4l-0.3-0.1l-0.7-0.3l-0.3-0.2l-0.2-0.2l0-0.2l0.2-1.9l0.4,0.1l5.2,2.3l0.2,0.1
		L617.4,121L617.4,121L617.4,121z M662.8,83.9l0.7-0.8l0.9-0.4l1.3,0.6l1.3,0.7l0.1,0.3l0.1,1.5l-0.1,0.5l-0.2,0.3l-0.2,0.2l-1,0.2
		l-2.7-1.7l-0.3-0.3l-0.3-0.5l0-0.4l0.1-0.1L662.8,83.9L662.8,83.9z M623.1,106.3l-0.7,0.6l-1.3,0.5l-1,1.2l-1.4,1.1l-0.2,0.1
		l-0.5,0.1l-0.3-0.2l-0.3-0.4l0-0.2l0.4-0.7l0.9-0.6l1.1-2l0.3-0.3l0.3-0.2l0.2-0.1l1.6-0.4l0.4,0.1l0.3,0.2l0.3,0.6l0,0.4
		L623.1,106.3L623.1,106.3z M646.6,100.2l0.1-0.3l0.3-0.2l1.4,1l1.3-0.9l0.4-0.1l0.8,1.3l-0.1,0.3l-1.1,1.9l-1.3,1.8l-0.3,0.2
		l-0.5,0.3l-0.3,0l0-0.2l0.6-0.5l0.4-0.5l0.2-0.3l0.1-0.2l0-0.2l0-0.6l-0.5-1.2l-0.2,0l-0.1,0.1l-0.2,0.4l-0.9-0.2l0-1.6l0-0.2
		L646.6,100.2L646.6,100.2z M547.1,126.6l-0.6,0.3l-3.6,1.2l0-0.5l0.2-0.7l0.1-0.2l0.2-0.4l0.3-0.5l0.4-0.5l0.2-0.2l0.2-0.1l0.4-0.2
		l0.2-0.1l1.1-0.3l0.3,0l0.2,0.1l0.6,1.4l0,0.2L547.1,126.6L547.1,126.6z M595.9,115l-0.9,1.9l-0.2,0.2l-1.2,0.4l-0.6-0.1l-1.1-1.5
		l0-0.2l0.1-0.2l0.3-0.2l1.8-0.9l0.2-0.1l0.3,0l0.6,0l0.8,0.3l0,0.2L595.9,115L595.9,115z M596.2,112.9l-3,0.6l-1.3,0.2l-2.1,0.2
		l-0.2-0.1l0-0.4l0.6-0.3l2.7-0.9l2-0.5l0.8-0.1l1,0.8l0.1,0.2l0,0.2l-0.2,0.1L596.2,112.9L596.2,112.9L596.2,112.9z M667.2,55.2
		l0,0.2l0,0.3l-0.8,1.9l-0.1,0.3l-0.8,0.6l-0.2,0.1l-0.3,0l-0.4-0.1l-0.3-0.1l-0.1-0.2l0.1-0.3l1-2.5l0.5-0.8l0.4-0.3l0.4,0l0.3,0.2
		l0.4,0.4l0.1,0.2L667.2,55.2L667.2,55.2z M664.3,97.8l-0.5,0.9l-0.8,1.8l-0.2,0.2l-1-1.1l0-0.5l0-0.5l0.1-1l0.1-0.2l0.4-0.8l0.2-0.1
		l1.2-0.3l0.3,0.2l0.1,0.2L664.3,97.8L664.3,97.8z M690.2,6.4l0.3-0.3l1.2-0.3l0.6,0.2l0.4,0.4l0.1,0.3l-0.1,1.5l-0.1,0.7l-0.1,0.1
		l-1.2,0.2l-0.7-0.4l-0.2-0.3L690,7.4l0-0.4l0.1-0.3l0.1-0.2L690.2,6.4L690.2,6.4z M615,96.1l-0.5,0.1l-0.4-0.1l-0.3-0.1l-0.2-0.1
		l-1.3-0.6l-0.1-0.1l-0.6-1.1l-0.4-1.1l0.7,0.1l2.9,1.3l0.2,0.4l0.3,0.8l0.1,0.3l-0.1,0.2L615,96.1L615,96.1z M599.8,110l-0.1,0.2
		l-0.2,0.2l-1.5,0.3l-0.2,0l-1.1-0.1l-0.2-0.1l-0.1-0.2l0-0.3l0.2-1l0.2-0.4l0.3-0.1l1.2-0.2l0.5,0l1.2,1.3L599.8,110L599.8,110z
		 M543.7,125.6l0,0.1l-1,1.6l-0.3,0l-1.1-0.8l-1.7-1.5l0-0.2l0.3-0.1l0.2-0.1l0.4-0.1l0.3,0l0.3,0l1.6,0.4l1,0.3l0.2,0.1L543.7,125.6
		L543.7,125.6z M580.1,119.9l-0.1,0.3l-0.2,0.1l-2.1-0.4l-0.6-0.8l-0.1-0.2l0-0.3l0.1-0.9l0.3-0.9l0.2,0l0.4,0.2l1.1,1.2l1,1.2
		l0.1,0.2L580.1,119.9L580.1,119.9z M560.5,115.4l-0.8,0.1l-2,0.2l-0.9-0.1l-0.3-0.1l-0.1-0.2l0.7-0.9l0.3-0.4l0.2-0.1l1.4-0.6
		l0.2,0.1l1.6,1.7L560.5,115.4L560.5,115.4z M611.9,124.5l-0.1,0.1l-0.2,0.1l-2.2-0.4l-0.9-0.2l-0.2-0.1l-0.3-0.2l-0.2-0.3l-0.1-0.3
		l0.1-0.4l0.3-0.3l0.4-0.1l0.9,0.1l0.9,0.2l0.7,0.2l0.3,0.1l0.2,0.2l0.6,0.6l0.1,0.3L611.9,124.5L611.9,124.5z M601.4,110.8l-0.5,0.9
		l-0.4,0.6l-0.2,0.2l-1.2,0.2l-0.7,0l-0.4-0.1l-0.4-1.1l0.1-0.2l2-0.8l0.2-0.1l1.2,0l0.3,0.1l0,0.2L601.4,110.8L601.4,110.8z
		 M691.4,2.3l-0.2,0.7l-0.2,0.1l-0.4,0.1l-0.2,0l-1-0.2l-0.9-1.2l0-0.3l0-0.2l0.4-1l0.4-0.2l1.7,1.3l0.4,0.8L691.4,2.3L691.4,2.3z
		 M681,57.6l0.1-0.4l0.1-0.3l0.5-0.3l0.3,0.1l0.1,0.2l0.6,2.6l0,1.1l-0.4,0.7l-0.3,0.2l-0.2-0.2l-0.3-0.5l-0.5-1.5l-0.1-1.1L681,57.6
		L681,57.6L681,57.6z M586.8,124.2l-0.2,0.2l-0.2,0.1l-1,0.4l-0.4,0l-1.4-0.5l-0.1-0.2l0-1.1l0.2-0.1l0.3,0l3,0.1l0.1,0.2l0,0.5
		L586.8,124.2L586.8,124.2z M671.3,86.2l-0.1,0.2l-1,0.7l-1,0.2l-0.9-1.6l0-0.3l0.6-1.5l0.3-0.1l0.5,0.1l0.5,1l0,0.2L671.3,86.2
		L671.3,86.2z M615.6,119l-0.5-0.1l-0.3-0.1l-0.2-0.2l-2.2-2.2l0.3-0.5l0.4-0.3l0.7,0l0.5,0.2l0.6,0.8l1.1,1.7l0.3,0.6l-0.1,0.2
		L615.6,119L615.6,119z M664.3,56.2l0,0.2l-0.9,1.3l-0.4,0.4l-0.3,0.2l-0.3,0.1l-0.2-0.1l-0.1-0.1l-0.5-2.4l0.2-0.1l0.5-0.2l0.5-0.1
		l1,0.3l0.5,0.2l0.2,0.2L664.3,56.2L664.3,56.2z M651.3,106l-0.1,0.2l-1.2,0.3l-1.9-0.1l-0.2-0.1l0-0.2l1.3-1.9l0.3-0.3l0.3,0
		l0.2,0.1l1,1.4l0.1,0.2l0.1,0.2L651.3,106L651.3,106z M636.1,100.1l0.1,0.4l0,0.2l-0.5,0.5l-0.2,0.1l-0.3-0.1l0-0.2l-0.1-0.1
		l-0.3,0.5l-0.2,0.6l-0.1,1.2l0,0.3l0.3,1.3l0.2,0.3l-0.2,0.2l-0.7-0.7l-0.4-1.7l0.6-2.1l0.1-0.2l0.7-0.6l1.1,0.2L636.1,100.1
		L636.1,100.1z M609.8,105.9l0,0.1l-0.1,0.3l-0.1,0.3l-0.2,0.1l-2.5,1.2l-0.4,0l-0.2-0.1l0-0.3l0.5-0.9l1-0.8l1-0.4l0.3,0
		L609.8,105.9L609.8,105.9z M707.1,16.7l-1.2,0.5l-0.1,0.2l0,0.8l0.5,1.7l-0.1,0.2l-0.2,0.1l-0.4,0l-0.5-0.2l-0.1-0.4l0-0.2l0.1-0.4
		l0.1-0.3l0.1-0.6l0-1.5l-0.4-0.5l-0.1-0.2l0.2-0.6l0.3-0.2l1.2,0.4l0.2,0.2l-0.2,0.7l0,0.2L707.1,16.7L707.1,16.7z M684.3,8.5
		l0.2-0.7l0.6-1.6l0.2-0.2l1.9-0.6l0.1,0.2L687.2,6L685,9.2l-0.3,0.2l-0.3-0.1l-0.1-0.1l-0.1-0.3l0.1-0.3L684.3,8.5z M621.5,119.3
		l-0.9,0.9l-1.2,0.2l-0.7-2.5l0.1-0.3l0.2-0.1l2,1l0.3,0.4l0.1,0.3L621.5,119.3L621.5,119.3z M599.6,105.8l-0.2,1l-0.1,0.1l-0.3,0.4
		l-0.9,0.3l-0.2-0.1l-0.4-0.3l-0.5-0.6l-0.1-0.2l0.1-0.3l0.1-0.3l0.2-0.2l0.2-0.1l0.2-0.1l0.4-0.1l0.5,0l0.9,0.2L599.6,105.8
		L599.6,105.8L599.6,105.8z M541.7,123.9l0,0.3l-0.1,0.2l-0.3,0l-1.3-0.5l-0.3-0.2l0-1.2l0.1-0.7l0.2-0.4l1.3,1.3l0.2,0.2l0.1,0.2
		l0.1,0.4L541.7,123.9L541.7,123.9z M618.5,119.6l-0.1,0.3l-0.2,0.1l-0.2,0.1l-0.5,0l-0.5-0.2l-1.5-2.5l0.5-0.2l2.1,1.2l0.4,0.9
		l0.1,0.2L618.5,119.6L618.5,119.6z M700.1,18.5l-0.2,0.1l-2.6-0.5l-0.3-0.2l-0.2-0.3l0-0.2l0.3-0.2l0.2-0.2l0.3-0.2l0.2-0.1l0.4-0.2
		l0.9,0l0.6,0.7l0.2,0.3l0.1,0.4L700.1,18.5L700.1,18.5z M685.2,53.3l0,0.2l-0.1,0.2l-0.2,0.2l-0.3,0l-0.2-0.2l-0.2-0.2l-1.1-2
		l-0.1-0.2l0-0.2l0.1-0.1l0.3-0.2l0.4,0l0.8,0.5l0.6,1.3L685.2,53.3L685.2,53.3z M643.3,109.2l0.3,0.1l1.1,0.6l0.3,0.4l0.3,0.9l0,0.3
		l-0.1,0.2l-0.3,0.3l-0.3,0l-0.8-0.8l-0.5-0.6l-0.5-0.6l0-0.2l0.1-0.2l0.3-0.3L643.3,109.2z M706.9,11.5l-0.8,1.5l-0.7,0.9l-0.4-0.1
		l-0.1-0.1l-0.1-0.3l0.1-0.8l0.3-1l0.7-0.5l0.9-0.1L706.9,11.5L706.9,11.5z M611.7,106.7l-0.2,1.1l-0.2,0.2l-1.1,0.1l-0.9-0.4
		l-0.1-0.2l0-0.2l0.1-0.2l0.2-0.1l0.3-0.3l1-0.6l0.3,0.1l0.3,0.3L611.7,106.7L611.7,106.7z`,
        cx: 600,
        cy: 90
	},
	{
		id: 'CL-RM',
		d: `M265.4,56.6l-0.4,0l-0.3,0.1l-0.3,0.4l-0.3,0.7l-0.5,0.5l-0.3,0.1l-1.1,0l-0.7,0.2l-0.2,0.3l0,0.4l0.3,0.4
		l0.1,0.3l-0.3,1l-0.9-0.1l-1.8,0.6l-0.3,0.3l-0.2,0.5l0,0.5l0.7,1.7l0.4,0.6l0.1,1l-0.1,0.4l0.7,1.2l2.2,1.8l0.4,0.1l0.7-0.1
		l0.3,1.2l0.1,1.4l0,0l-1.7,1.2l-0.7,1.8l-1.6,0.9l0.2,2.3l-0.6,1.4l0,0l-0.5-0.8l0,0l-1.6-0.4l0-0.3l0-0.8l0.2-0.7l0-0.5l-0.8-0.7
		l-2.3-0.6l-1.3,0.5l-1.2,0.2l-0.3,0l-0.2-0.4l-1-1l-0.6-0.4l-0.4-0.1l-1.2,0l-0.4-0.2l-0.2-0.9l0-0.9l-0.7-1l-2.1,0.1l-0.8,0.2
		l-0.6-0.1l-0.1-0.3l-0.6-1.6l0-2l0.3-1.3l0.3-0.7l0.5-0.6l0.7-0.2l0.7-0.5l0-0.3l-0.8-0.9l0-0.3l1.1-1l0.6-1.5l-1.1-1.2l-0.4-0.7
		l0,0l0.4-0.2l0.8,0.1l0.5,0.1l0.4-0.1l2-1.4l-0.1-0.3l-0.5-0.3l-0.4-0.4l-0.1-0.3l0.1-0.4l0.6-1l1.8-0.4l0.4,0.1l0.4,0.4l2,0.8
		l3.8,0.5l0.8,0l1.1-0.3l3.5-0.6l2.3-0.5l0.8,1l0,0.7l-0.1,0.3l0,0.4l0.1,0.6L265.4,56.6z`,
        cx: 258,
        cy: 63
	},
	{
		id: 'CL-LR',
		d: `M377.2,80.7l-0.3,2.6l0.3,2.2l1.7,1.1l2,1.6l-1.1,2.8l-2.2,2.5l-1.4,2l-2.5,1.4l-0.8,2.2l1.7,2.2l-0.3,1.4
		l-1.4,0.9l-0.3,4.3l0,0l-1.7-0.3l-1.2-0.9l-1.1,0.1l-0.5,0.2l-0.4,0.4l-0.4,0.2l-0.5-0.3l-0.2-0.2l-1.3-2.7l0.2-1.2l-0.6-0.4
		l-2.2,0.1l-2.7-1l-0.4-0.2l-1.3-0.5l-1.5-0.4l-0.6,0.3l0,0l0.1-0.5l0.1-0.4l0-0.4l-0.1-0.4l-0.2-0.4l-0.4-0.4l-0.5-0.3l-0.9-0.7
		l-0.1-2.1l0.2-0.3l1-0.2l0.8-0.4l0.5-0.4l0.2-0.4l0.1-0.4l0-0.4l0-0.4l-0.2-1.1l0-0.4l0.7-2.1l0.3-0.7l0.1-0.5l-0.5-1.3l-0.2-0.4
		l-0.3-0.3l-0.4-0.4l-0.2-0.4l-0.1-0.4l0.2-2.3l0.1-0.4l0.3-0.5l0.4-0.4l0.6-0.8l0-1l0-0.3l0.4-0.2l0.5-0.1l0.4,0l0,0l0.2,0.1
		l0.9,0.3l1.9-0.1l2.3-0.5l1.8-0.5l1.8,0.4l0.8,1.2l0.5,0.6l1.7,0.4l0.8-0.4l1-1l-0.4-0.2l0.3-0.5l0.7-0.1l1.3,0.5l0.4,0.2l0.1,0.3
		l-0.3,0.3l0,0.3L377.2,80.7z`,
        cx: 366,
        cy: 90
	},
	{
		id: 'CL-AP',
		d: `M21,43.8l0,2.1l1.4,1.4l1.7,0.3l0.3,2.2l-1.6,2.2l0,3.9l1.7,2.2l1.4,2.2l0.1,2.3l0,0l-0.6,0l-0.4-0.2l-0.6,0
		l-4,0.8l-0.6,0.2l-2.9,0.2l-0.9-0.1L14,63.3l-0.5,0.4L13.1,64l-0.4,0.5l0,0.1l-0.2-0.2l-0.2-0.3l-0.1-0.5l0.1-1.1l0-0.7l0-0.3
		l-0.1-0.6l-0.8-2l-0.3-0.5L9.8,57l-0.4-0.3L8,56l-0.8-0.3l-0.5-0.1l-2.2,0.6l-0.3,0.1l-0.2,0.2l-0.3,0.2l-0.6-0.1l-0.4-0.2l-0.4-0.3
		l0.1-0.6l0.1-1.2L0,51.9l1.9-0.2l1.4-1.6l0.3-0.3l0.8-0.4l0.4-0.1l1,0l0.5-0.1L6.7,49L7,48.8l0.7-2.2l0.1-0.9l0.4,0l0.3,0.1l0.3,0.5
		l0.3,0.3l0.4,0.1l0.4-0.2l0.3-0.3l0.4-0.3l2.4-0.3l0.3-0.2l0.4-0.3l0.3-0.1l0.4-0.1l0.4,0l0.8,0.1l0.8-0.1l0.7-0.1l1.3-0.4l1.5-0.7
		l0.5-0.3L21,43.8z`,
        cx: 15,
        cy: 54
	},
	{
		id: 'CL-NU',
		d: `M294.8,95.1l0.3,0.2l2.6,0.3l3.6,0.3l1.4,0.5l0.2,0l0.7-0.2l0-0.7l0.7-0.1l0.4,0.3l0.6-0.2l1-0.3l-0.2-0.4
		l0.3-0.3l0.7-0.3l0.1-0.8l0.8-0.8l0.7-0.2l0.4,0.1l0.9-0.2l0.3-0.5l0.5,0.3l0.4-0.2l-0.1,1.3l0.6,0.2l0.2-2.1l-0.5-0.2l0-0.4
		l0.5-0.2l0-0.8l-0.3-0.3l1.2-0.8l0.4-0.6l0-0.6l0.3-0.4l0.1-0.3l0.4-0.1l0.6,0.6l0.2,0.5l0.6,0.1l0.6,0l0.6,0l-0.2-0.5l0.2-1
		l-0.4-0.6l0.2-0.7l0-0.3l-0.3-0.2l0-0.2l0.4-0.6l0-0.4l-0.1-0.6l-0.2-0.6l-0.3-0.3l-0.6-0.2l-0.2-0.4l-0.1-0.5l0.2-0.6l0.1-0.4
		l0.3-0.4l-0.3-0.4l0-0.5l-0.1-0.5l0.1-0.3l-0.6,0l-0.4-0.2l-0.4,0l-0.3,0l-0.4,0l-0.1-0.4l0.3-0.4l0.4-0.5l0.4-0.1l0.3-0.5l0-0.3
		l-0.3-0.2l0.1-0.4l0.1-0.4l0.4-0.4L315,73l-0.1-0.6l-0.1-0.4l0.4-0.6l-0.1-0.4l-0.1,0l-2.5,0.5l-2.3,0.6l-1.8-0.7l-3.5-1.1l-1.1-0.3
		l-0.1-0.3l0,0.1l-0.5,0.4l-0.6,0.2l-0.5,0.1l-0.2,0.5l0.1,0.3l0.2,0.3l1.5,1.1l0.5,0.1l0.4,0l0.5,0.4l-0.1,0.6l-0.8,1.4l-0.3,0.3
		l-1.2,1.5l-0.3,1.2l-0.3,0.9l-0.3,0.5l-0.3,0.4l-0.4,0.2l-0.8,0.4l-0.1,0.9l0.1,0.3l0.2,0.4l0,0.3l-0.2,0.8l-0.2,0.3l-0.3,0.1
		l-0.4,0.2l-1.1,1.1l0.5,0.1l0.4,0l1.2,1l0,1.8l-0.1,0.4l-1.4,2.7l-1.8,2.1l-0.6,0.1l-0.2,0.2L294.8,95.1L294.8,95.1z`,
        cx: 305,
        cy: 80
	}
];

export default MapData;