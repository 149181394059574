import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import mapStyle from './mapstyle';

const ContactMap = () => {

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, []);
    
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);


    const containerStyle = {
        width: '100%',
        height: '520px'
    };
    
    const center = {
        lat: -33.4369611,
        lng: -70.8386921
    };

    return <LoadScript
        googleMapsApiKey="AIzaSyBkP81eshhGrA1sLDBwMfB-UYi5lZtaJgQ"
        >
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                styles: mapStyle 
            }}
        >
            <Marker position={center}></Marker>
            { /* Child components, such as markers, info windows, etc. */ }
            <>
            </>
        </GoogleMap>
    </LoadScript>
}

export default ContactMap;