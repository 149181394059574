import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';

function MenuIndicadores(props){
    const {location} = props;

    if(!location.pathname.match(/^\/indicadores.*/i))
        return false;
    const uriArray = location.pathname.split('/');
    const subMenu = uriArray.length >2 ? uriArray[2] : null;

    console.log(subMenu);

    return <Fragment>
        <div className={"header-submenu-item " + (subMenu == null ? 'active':'')}>
            <Link to="/indicadores" className="title">Destacados</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "macroeconomia"? 'active':'')}>
            <Link to="/indicadores/macroeconomia" className="title">Macroindicadores</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "Inmobiliarios"? 'active':'')}>
            <Link to="/indicadores/Inmobiliarios" className="title">Inmobiliarios y Habitacionales</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "sustentabilidad"? 'active':'')}>
            <Link to="/indicadores/sustentabilidad" className="title">Sustentabilidad</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "Innovacion"? 'active':'')}>
            <Link to="/indicadores/Innovacion" className="title">Innovación y BIM</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "Seguridad"? 'active':'')}>
            <Link to="/indicadores/Seguridad" className="title">Seguridad Laboral</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "Cchc"? 'active':'')}>
            <Link to="/indicadores/Cchc" className="title">CChC</Link>
        </div>
        <div className={"header-submenu-item " + (subMenu === "UnidadAnalisisTerritorialCORFO/Carreras"? 'active':'')}>
            <Link to="/indicadores/UnidadAnalisisTerritorialCORFO/Carreras" className="title">Análisis Territorial CORFO - Carreras</Link>
        </div>
    </Fragment>
}

export default MenuIndicadores;