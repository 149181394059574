import React, {Component} from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FreeIcons from '@fortawesome/free-solid-svg-icons';
import * as BrandIcon  from '@fortawesome/free-brands-svg-icons';
class RRSS extends Component{
    constructor(props){
        super(props);
    }

    render() {
        let obj = {};
        if(this.props.banner && window.innerWidth > 767) {
            obj = {'position': 'absolute', 'top': 'calc(140px + 308px)'}
        }
        
        return <div className="rrss_container" style={obj}>
            <a className="rrss_box" href="https://www.linkedin.com/company/ctecnologico/" target="_blank">
                <FontAwesomeIcon icon={BrandIcon.faLinkedinIn}/>
            </a>
            <a className="rrss_box" href="https://www.facebook.com/CTeCInnovacion/" target="_blank">
                <FontAwesomeIcon icon={BrandIcon.faFacebookF} />
            </a>
            <a className="rrss_box" href="https://www.instagram.com/ctec_construccion/" target="_blank">
                <FontAwesomeIcon icon={BrandIcon.faInstagram} />
            </a>
            <a className="rrss_box" href="https://twitter.com/CTecInnovacion" target="_blank">
                <FontAwesomeIcon icon={BrandIcon.faTwitter} />
            </a>
            <a className="rrss_box" href="https://www.youtube.com/channel/UCpaoF-wHcaCkNlSyIxoLaIw" target="_blank">
                <FontAwesomeIcon icon={FreeIcons.faPlay} />
            </a>
            <a className="rrss_box" href="https://www.flickr.com/people/ctecinnovacion/" target="_blank">
                <img className="carousel-img" src={process.env.PUBLIC_URL+'/assets/img/2dots.svg'} alt=""/>
            </a>
            <a className="rrss_vertical_box rrss_separator" href="https://ctecinnovacion.cl/" target="_blank">
                CTEC
            </a>
            <a className="rrss_vertical_box" href="https://cvschile.cl/" target="_blank">
                CVS
            </a>
        </div>;
    }
}

export default RRSS;