import React, { useEffect } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAnimatedIncremental } from "../../hooks/useAnimatedIncremental";

const clLocale = Intl.NumberFormat("es-CL");

const formatYAxis = (tickItem) => {
  return clLocale.format(tickItem / 1000000) + " MM";
};

const formatXAxis = (tickItem) => {
  return tickItem.length > 8 ? tickItem.substring(0, 8) + "..." : tickItem;
};

const tooltipFormatter = (value) => {
  return [`$ ${clLocale.format(value)}`, "Monto"];
};

const tooltipLabelFormatter = (value) => {
  return `Categoría: ${value}`;
  // : ${
  //   regionesNombre[
  //     Object.keys(regionesRomanos).find((key) => regionesRomanos[key] === value)
  //   ]
  // }
};

const BarChartCard = (props) => {
  const { title, data, loading, count } = props;
  const [value, setValueFromTo] = useAnimatedIncremental(count);

  useEffect(() => {
    console.log("(count) 👉", count);
    if (value != count) setValueFromTo(0, count);
  }, [count]);

  return (
    <div className="row bar-chart-container">
      <div className="col-12 col-md-5">
        <h5 className="card-header__title plot-title text-white">
          {title.replace(
            "__",
            `${clLocale.format(Math.floor(value / 1000000))}MM`
          )}
        </h5>
        <p className="d-block d-sm-none chart-help-text">
          Has click sobre el gráfico para ver el nombre de las categorías
        </p>
      </div>
      <div className="col-12 col-md-7 align-center" style={{ height: 350 }}>
        {loading ? (
          <div className="loader-chart-container">
            {/* <LinearProgress className="linear-loader" /> */}
            <p>Obteniendo información...</p>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={data}
              margin={{ top: 25, right: 5, left: 5, bottom: 25 }}
            >
              <XAxis
                stroke="white"
                dataKey="category"
                angle={90}
                interval={0}
                tickMargin={35}
                height={50}
                tickFormatter={formatXAxis}
              />
              <YAxis stroke="white" tickFormatter={formatYAxis} />
              <Tooltip
                formatter={tooltipFormatter}
                labelFormatter={tooltipLabelFormatter}
                cursor={false}
              />
              <Bar dataKey="amount" fill="#0096af" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
    // <div className="card kpi-card chart-card">
    //   <div className="card-header plot-header">
    //     <h5 className="card-header__title plot-title">
    //       {title.replace(
    //         "__",
    //         `${clLocale.format(Math.floor(value / 1000000))}MM`
    //       )}
    //     </h5>
    //   </div>

    //   <div className="card-body text-center map-container">
    //     {loading ? (
    //       <>
    //         <LinearProgress className="linear-loader" />
    //         <p>Obteniendo información...</p>
    //       </>
    //     ) : (
    //       <ResponsiveContainer width="95%" height={350}>
    //         <BarChart
    //           data={data}
    //           margin={{ top: 25, right: 15, left: 10, bottom: 25 }}
    //         >
    //           <XAxis
    //             stroke="white"
    //             dataKey="category"
    //             angle={90}
    //             interval={0}
    //             tickMargin={35}
    //             height={50}
    //             tickFormatter={formatXAxis}
    //           />
    //           <YAxis stroke="white" tickFormatter={formatYAxis} />
    //           <Tooltip
    //             formatter={tooltipFormatter}
    //             labelFormatter={tooltipLabelFormatter}
    //             cursor={false}
    //           />
    //           <Bar dataKey="amount" fill="#0096af" />
    //         </BarChart>
    //       </ResponsiveContainer>
    //     )}
    //   </div>
    // </div>
  );
};

export default BarChartCard;
