import { useState } from "react";

const INCREMENTAL_TIME = 2000; //in ms
const INTERVAL_TIME = 25; //in ms
/**
 * Cambia de un número a otro con animación
 *
 * @param initialValue valor inicial.
 */
export const useAnimatedIncremental = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const animateFromTo = (fromValue, toValue) => {
    const range = Math.abs(fromValue - toValue);
    const increment = (range / INCREMENTAL_TIME) * INTERVAL_TIME;
    const iterations = range / increment;
    let iteration = 0;
    const interval = setInterval(() => {
      const newValue =
        fromValue < toValue
          ? value + increment * iteration
          : value - increment * iteration;
      setValue(Math.floor(newValue));
      iteration += 1;
      if (iterations === iteration - 1) {
        setValue(toValue);
        clearInterval(interval);
      }
    }, INTERVAL_TIME);
  };

  return [value, animateFromTo];
};
