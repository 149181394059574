import React, { Component, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavVisit from "./components/commons/navVisitHeader/NavVisit";
import Footer from "./components/commons/Footer";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./util/fixintl";
import Contacts from "./components/Contacts";
//import EnConstrucción from "./components/EnConstruccion/";
import SmartLicitaciones from "./components/SmartLicitaciones";
import RRSS from "./components/commons/rrss";

/*
import Home from "./components/Home";
import Contacto from "./components/Contacto";
import Macroeconomia from "./components/Macroeconomia";
import Cchc from "./components/Cchc";
import Sustentabilidad from "./components/Sustentabilidad";
import Innovacion from "./components/Innovacion";
import Inmobiliarios from "./components/Inmobiliarios";
import Seguridad from "./components/Seguridad";
import Indicadores from "./components/Indicadores";

// import HeaderQuienes from './components/commons/HeaderQuienes';
import Quienes from "./components/Quienes";
import Fuentes from "./components/Fuentes";
import Huincha from "./components/commons/Huincha";
import Sharing from "./components/commons/Sharing";
import AdminLogin from "./components/admin/AdminLogin";
import AdminPanel from "./components/admin/AdminPanel";
import Results from "./components/results";
import Details from "./components/Details";
import UATCarreras from "./components/UATCarreras";
*/

const App = () => {
  const [admin, setAdmin] = useState(null);

  return (
    <Router>
      <Provider store={store}>
        <NavVisit />
        <Switch>
          {/*<Route path="/" exact={true} component={EnConstrucción}/>
          <Route path="/landing" exact={true} component={SmartLicitaciones} />*/}
          <Route path="/" exact={true} component={SmartLicitaciones} />
          <Route path="/contacto" exact={true} component={Contacts} />
          <Route
            component={() => (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "3em",
                  marginBottom: "3em",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>ERROR 404</h2>
                <p>Página no encontrada</p>
              </div>
            )}
          />
        </Switch>
        <RRSS />
        <Footer />
      </Provider>
    </Router>
  );
};
export default App;
