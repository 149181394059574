import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import AppBar from "../../commons/appBar";

import MainLink from "./component/MainLink";
import MenuIndicadores from "./submenus/indicadores";

import "./styles.scss";

class NavVisit extends Component {
  render() {
    const { location } = this.props;

    return (
      <div className="header">
        <div className="header-logo">
          <Link to="/">
            <div className="logo-container">
              <div className="logo" />
            </div>
          </Link>
        </div>
        <div className="header-menu">
          
          {/*<MainLink to="/landing" location={location} regex={/^\/landing$/i}>
            SmartLicitaciones
          </MainLink>*/}
          <MainLink to="/" location={location} regex={/^\/$/i}>
            SmartLicitaciones
          </MainLink>
          <MainLink to="/contacto" location={location} regex={/^\/contacto$/i}>
            Contacto
          </MainLink>
          
        </div>
        <div className="header-submenu"></div>
      </div>
    );
  }
}

export default withRouter(NavVisit);
