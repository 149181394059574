import React, { Fragment, useEffect, useState } from "react";
import svgData from "./MapData";

const clLocale = Intl.NumberFormat("es-CL");

const AnimatedCircle = (props)=>{

  const [innerRadius,setInnerRadius] = useState(0);

  setTimeout(()=>{
    setInnerRadius(props.style.r);
  },1);

  return <circle {...props}
    style={{
      ...props.style,
      r: innerRadius
    }}
  />
}

const ChileMap = (props) => {
  let { title, data, loading } = props;
  const [isOverRegion,setIsOverRegion] = useState({});
  const valuesArray = data.map((item) => item.quantity);
  const maxValue = !!Math.max.apply(Math, valuesArray)
    ? Math.max.apply(Math, valuesArray)
    : 1;

  const calcPonderation = (value) => (maxValue ? value / maxValue : 0);

  const htmlToElement = (html) => {
    var template = document.createElement("template");
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
  };

  useEffect(() => {
    if (!loading) {
      const elements = document.querySelectorAll("#chile-map path");
      for (let index = 0; index < elements.length; index++) {
        elements[index].addEventListener("mouseenter", (event) => {
          try {
            const data = event.target.attributes.data
              ? JSON.parse(event.target.attributes.data.value)
              : {};

            const {id, quantity, name, amount } = data;
            const element = htmlToElement(`
              <div class="map-popover" style="top: ${event.clientY + 20}px; left: ${
                  event.clientX - 5
                }px;">
                <h5>${name}</h5>
                <p>N° de licitaciones: <b>${quantity}</b></p>
                <p>Monto Total: <b>$${clLocale.format(amount)}</b></p>
              </div>
            `);
            element.id = `${event.target.attributes.id.value}-${quantity}`;
            document.body.appendChild(element);
            
            // Indica cual es la región con mouse encima
            const overlapState = {};
            overlapState[id] = true;
            setIsOverRegion(overlapState);
          } catch (e) {
            console.log(e);
          }
        });

        elements[index].addEventListener("mouseleave", (event) => {
          try {
            const data = event.target.attributes.data
              ? JSON.parse(event.target.attributes.data.value)
              : {};
            const { id, quantity } = data;
            const delElementId = `${event.target.attributes.id.value}-${quantity}`;
            document.getElementById(delElementId).remove();

            // Indica cual es la región sin mouse encima
            const overlapState = {...overlapState};
            overlapState[id] = false;
            setIsOverRegion(overlapState);
          } catch (e) {}
        });
      }
    }
  }, [loading]);

  return (
    <div className="card kpi-card chart-card chile-map-card">
      <div className="card-header plot-header">
        <h5 className="card-header__title plot-title text-left">
          Licitaciones por región
        </h5>
      </div>
      <div className="card-body text-center map-container">
        {/* <div className="map-text">
          <p>
            Plataforma tecnológica para el sector construcción que, a través de
            inteligencia de datos, gestiona de forma eficiente el seguimiento y
            la búsqueda de proyectos, permitiendo a tu empresa estar informada y
            anticipar oportunidades de negocio para la toma de decisiones y
            participación en futuras licitaciones. La plataforma hoy cuenta con
            fuentes de información de <b>Metro</b>, <b>Mercado Público</b> y el
            <b>Servicio de Evaluación Ambiental (SEIA)</b>.
          </p>
          <p>
            Conoce más sobre la plataforma y sus oportunidades solicitando una
            demo{" "}
            <a href="https://google.cl" target="_blank">
              aquí
            </a>
          </p>
          <p>(*) Licitaciones por región</p>
        </div> */}
        {loading ? (
          <>
            {/* <LinearProgress className="linear-loader" /> */}
            <p>Obteniendo información...</p>
          </>
        ) : (
          <svg
            className="chile-map"
            id="chile-map"
            mapsvggeoviewbox="-109.449403 -17.500036 -66.416233 -55.907355"
            version="1.1"
            viewBox="0 0 707.1 134.5"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            xmlnscc="http://creativecommons.org/ns#"
            xmlnsdc="http://purl.org/dc/elements/1.1/"
            xmlnsmapsvg="http://mapsvg.com"
            xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlnssvg="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            y="0px"
          >
            {svgData.map((v, k) => {
              return (
                <path
                  style={{
                    opacity:
                      0.2 + calcPonderation(data[k] ? data[k].quantity : 0),
                  }}
                  data={JSON.stringify({
                    ...data[k],
                    id: v.id //Integra la id de la región en los datos.
                  })}
                  id={v.id}
                  d={v.d}
                />
              );
            })}
            {svgData.map((v, k) => {
              return (
                <AnimatedCircle
                  style={{
                    pointerEvents: "none",
                    transition:'r 1s, fill 1s',
                    r: (isOverRegion[v.id]? 60 : 40) * calcPonderation(data[k] ? data[k].quantity : 0),
                    fill:isOverRegion[v.id]? "#005360":"#0096af" 
                  }}
                  cx={v.cx ?? 0}
                  cy={v.cy ?? 0}
                  id={v.id}
                  data={JSON.stringify(data[k])}
                  r={0}
                  stroke="black"
                  stroke-width="0"
                  opacity={
                    0.2 + 0.2 * calcPonderation(data[k] ? data[k].quantity : 0)
                  }
                />
              );
            })}
          </svg>
        )}
      </div>
    </div>
  );
};

export default ChileMap;
