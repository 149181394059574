import React, { useState, Component } from "react";
import FooterCtec from './footer_ctec/index';
import RRSS from './rrss/index';

class Footer extends Component{
   // const [showRRSS, setShowRRSS] = useState(true);
   render() {
      return (
            <footer className="font-small pt-4">
               <FooterCtec/>
               {/* <RRSS url={this.props.url}/> */}
               <a href="link" className="btn-backTop">
                  <i className="fas fa-chevron-up"></i>
               </a>
            </footer>
      );
   }
}

export default Footer;
