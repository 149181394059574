import React, { Component } from "react";
import currentYearData from "../../data/current-year.json";
import {
  regionesRomanos,
  regionesNombre,
  regionsData,
} from "../../data/regiones";
import lastYearData from "../../data/last-year.json";
// import Grafico from "./Grafico";
import Huincha from "../commons/Huincha";
import ChileMap from "./ChileMap";
import PieChartCard from "./PieChartCard";
import BarChartCard from "./BarChartCard";
import { Button } from "@material-ui/core";

import {
  SMARTLICITACIONES_FOOTER_URL,
  SMARTLICITACIONES_SITE_URL,
  SMARTLICITACIONES_YOUTUBE_URL,
  SMARTLICITAIONES_API_URL,
} from "../../constants";
import { Link } from "react-router-dom";

import "./styles.scss";
import RRSS from "../commons/rrss";

const clLocale = Intl.NumberFormat("es-CL");

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;

const sortForBarChart = (item1, item2) => {
  if (item1.amount > item2.amount) {
    return -1;
  }
  if (item1.amount < item2.amount) {
    return 1;
  }
  return 0;
};

const sortBarData = (a, b) => parseInt(a.amount) - parseInt(b.amount);

export default class SmartLicitaciones extends Component {
  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    this.state = {
      regionChart: {
        title: `Cantidad y monto de licitaciones adjudicadas por región`,
        data: [],
        loading: true,
        value: 0,
      },
      barChart: {
        title: `El año ${currentYear - 1} se adjudicaron $__ en obras`,
        data: [],
        loading: true,
        value: 0,
      },
      pieChart: {
        title: `Durante el año ${currentYear - 1} se adjudicaron ... obras`,
        data: [],
        loading: true,
        total: 0,
        value: 0,
      },
      pieChartCurrent: {
        title: `Este año, a la fecha, se han adjudicado ... obras`,
        data: [],
        loading: true,
        total: 0,
        value: 0,
      },
      currentYear: currentYear,
    };
  }

  formatTypologyData(arrayData) {
    return arrayData.reduce((acc, curr) => {
      const categories = curr.categories;
      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        if (!acc.find((item) => item.category == category.name)) {
          acc.push({
            category: category.name,
            categoryCode: category.code,
            quantity: 1,
            amount: curr.estimatedAmount,
          });
        } else {
          const index = acc.findIndex((item) => item.category == category.name);
          acc[index].quantity += 1;
          acc[index].amount += curr.estimatedAmount;
        }
      }
      return acc;
    }, []);
  }

  regionData(arrayData) {
    let result = regionsData;
    arrayData.forEach((bidding) => {
      if(result[bidding.region - 1] != null){
        result[bidding.region - 1].amount = bidding.estimatedAmount;
        result[bidding.region - 1].quantity += 1;
      }
    });
    return result;
  }

  async loadRegionData(currentYear) {
    this.setState({
      regionChart: {
        ...this.state.regionChart,
        ...{ loading: true, data: [] },
      },
      barChart: {
        ...this.state.barChart,
        ...{ loading: true, data: [] },
      },
      pieChart: {
        ...this.state.pieChart,
        ...{ loading: true, data: [] },
      },
    });
    try {
      const response = await fetch(
        `${SMARTLICITAIONES_API_URL}/public-dashboard-data/${currentYear}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "GET",
        }
      );

      if (response.status != 200) {
        console.log("Error detectado");
        throw ["Query error", response];
      }

      const responseData = await response.json();

      const barData = responseData ? this.barData(responseData) : [];
      const regionData = responseData ? this.regionData(responseData) : [];
      const pieChartData = responseData
        ? this.formatTypologyData(responseData)
        : [];

      const sortDataForBarChart = [...pieChartData].sort(sortForBarChart);

      //Esto deja la categorías "Otros" al final
      sortDataForBarChart.push(
        sortDataForBarChart.splice(
          sortDataForBarChart.findIndex((item) => item.category === "Otros"),
          1
        )[0]
      );

      const totalCount = pieChartData.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      const totalAmount = pieChartData.reduce(
        (acc, curr) => acc + curr.amount,
        0
      );

      console.log(clLocale.format(totalCount));
      this.setState({
        regionChart: {
          ...this.state.regionChart,
          ...{
            loading: false,
            data: regionData,
          },
        },
        barChart: {
          ...this.state.barChart,
          ...{
            loading: false,
            data: sortDataForBarChart,
            title: `El año ${currentYear} se adjudicaron $${clLocale.format(
              totalAmount / 10000000
            )}MM en obras`,
          },
        },
        pieChart: {
          ...this.state.pieChart,
          ...{
            loading: false,
            data: pieChartData,
            title: `Durante el año ${currentYear} se adjudicaron ${clLocale.format(
              totalCount
            )} obras`,
            total: totalCount,
            value: totalCount,
          },
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({
        regionChart: {
          ...this.state.regionChart,
          ...{ loading: false, data: [] },
        },
        barChart: {
          ...this.state.barChart,
          ...{ loading: false, data: [] },
        },
        pieChart: {
          ...this.state.pieChart,
          ...{ loading: false, data: [] },
        },
      });
    }
  }

  async loadTypologyDataCurrent(currentYear) {
    this.setState({
      pieChartCurrent: {
        ...this.state.pieChartCurrent,
        ...{ loading: true, data: [] },
      },
    });
    try {
      const response = await fetch(
        `${SMARTLICITAIONES_API_URL}/public-dashboard-data/${currentYear}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "GET",
        }
      );

      if (response.status != 200) {
        console.log("Error detectado");
        throw ["Query error", response];
      }

      const responseData = await response.json();
      console.log("(responseData) 👉", responseData);
      const data = this.formatTypologyData(responseData);
      const total = data.reduce((acc, curr) => acc + curr.quantity, 0);

      this.setState({
        pieChartCurrent: {
          ...this.state.pieChartCurrent,
          ...{
            loading: false,
            data: data ?? [],
            title: `Este año, a la fecha, se han adjudicado ${clLocale.format(
              total
            )} obras`,
            total,
            value: total,
          },
        },
      });
    } catch (error) {
      this.setState({
        pieChartCurrent: {
          ...this.state.pieChartCurrent,
          ...{ loading: false, data: [] },
        },
      });
    }
  }

  componentDidMount() {
    const { currentYear } = this.state;
    this.loadRegionData(currentYear - 1);
    this.loadTypologyDataCurrent(currentYear);
  }

  barTitle(arrayData) {
    const lastYearAmount = clLocale.format(
      arrayData.reduce(function (r, project) {
        return r + project["estimatedAmount"];
      }, 0)
    );
    return `El año ${lastYear} se adjudicaron $ ${lastYearAmount} en obras`;
  }

  barData(arrayData) {
    const grouped = arrayData.reduce(function (r, project) {
      r[project.region] = r[project.region] || [];
      r[project.region].push(project);
      return r;
    }, Object.create(null));
    const keys = Object.keys(grouped);
    let result = [];
    let object = {};
    keys.forEach((key) => {
      object = {
        name: regionesRomanos[key],
        amount: grouped[key].reduce(function (a, b) {
          return a + b["estimatedAmount"];
        }, 0),
      };
      result.push(object);
    });
    return result;
  }

  pieTitle(year, arrayData) {
    const quantity = "" + clLocale.format(arrayData.length);
    return year == currentYear
      ? `El año ${year} llevamos adjudicados ${quantity} obras`
      : `El año ${year} se adjudicaron ${quantity} obras`;
  }

  pieData(arrayData) {
    const grouped = arrayData.reduce(function (r, project) {
      r[project.region] = r[project.region] || [];
      r[project.region].push(project);
      return r;
    }, Object.create(null));
    const keys = Object.keys(grouped);
    let result = [];
    let object = {};
    keys.forEach((key) => {
      object = {
        name: key,
        quantity: grouped[key].length,
      };
      result.push(object);
    });
    return result;
  }

  render() {
    const { regionChart, barChart, pieChart, pieChartCurrent } = this.state;
    return (
      <React.Fragment>
        <div className="charts-container">
          <div className="row" style={{ marginBottom: "32px" }}>
            <a
              href={SMARTLICITACIONES_SITE_URL}
              className="header-home__link login-button"
              target="_blank"
            >
              <span>Ir a mi sitio</span>
              <svg>
                <polyline
                  className="o1"
                  points="0 0, 250 0, 250 50, 0 50, 0 0"
                ></polyline>
                <polyline
                  className="o2"
                  points="0 0, 250 0, 250 50, 0 50, 0 0"
                ></polyline>
              </svg>
            </a>
          </div>
          <div className="row section1" style={{ marginBottom: "32px" }}>
            <div className="col-12 col-md-4">
              <h2>¿Qué es Smartlicitaciones?</h2>
            </div>
            <div className="col-12 col-md-8">
              <p>
                Plataforma tecnológica para el sector construcción que, a través
                de inteligencia de datos, gestiona de forma eficiente el
                seguimiento y la búsqueda de proyectos, permitiendo a tu empresa
                estar informada y anticipar oportunidades de negocio para la
                toma de decisiones y participación en futuras licitaciones. La
                plataforma hoy cuenta con fuentes de información de Metro,
                Mercado Público y el Servicio de Evaluación Ambiental (SEIA).
              </p>
              <p>
                Conoce más sobre la plataforma y sus oportunidades solicitando
                una demo{" "}
                <a href={SMARTLICITACIONES_FOOTER_URL} target="_blank">
                  aquí
                </a>
              </p>
              <div className="section1-logos-container">
                <img
                  src={`${window.PUBLIC_URL}/assets/img/white-mp.png`}
                  className="section1-logo"
                />
                <img
                  src={`${window.PUBLIC_URL}/assets/img/white-metro.png`}
                  className="section1-logo"
                />
                <img
                  src={`${window.PUBLIC_URL}/assets/img/white-seia.png`}
                  className="section1-logo"
                />
              </div>
            </div>
          </div>

          <div className="row section2">
            <div className="col-12 offset-md-6 col-md-6">
              <iframe
                className="embed-responsive-item"
                width="100%"
                height="350"
                src={SMARTLICITACIONES_YOUTUBE_URL}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
          </div>

          <div className="row section3">
            <ChileMap
              title={regionChart.title}
              data={regionChart.data}
              loading={regionChart.loading}
            />
          </div>

          <div className="row section4">
            <div className="col-md-8">
              <BarChartCard
                title={barChart.title}
                data={barChart.data}
                loading={barChart.loading}
                count={barChart.value}
              />
            </div>
          </div>

          <div className="row section5">
            <div className="col-md-8">
              <PieChartCard
                title={pieChart.title}
                data={pieChart.data}
                loading={pieChart.loading}
                total={pieChart.total}
                count={pieChart.value}
              />
            </div>
          </div>

          <div className="row section6">
            <div className="col-md-8">
              <PieChartCard
                title={pieChartCurrent.title}
                data={pieChartCurrent.data}
                loading={pieChartCurrent.loading}
                total={pieChartCurrent.total}
                count={pieChartCurrent.value}
                colors={[
                  "#004a57",
                  "#1f4f5b",
                  "#285866",
                  "#334449",
                  "#2c4f52",
                  "#396d83",
                ]}
              />
            </div>
          </div>

          <div className="row section7">
            <div>
              <a href={SMARTLICITACIONES_FOOTER_URL} target="_blank">
                <img
                  src={`${window.PUBLIC_URL}/assets/img/demo-aqui.jpg`}
                  className="footer-smart-licitaciones"
                />
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
