import React, { Component } from "react";
import toastr from "toastr";
// import { Text, StyleSheet, View, TouchableOpacity, Linking, Platform } from 'react-native';
import { Redirect } from "react-router-dom";
//import HeaderContacto from './commons/HeaderContacto';
import ContactMap from "./contactMap/ContactMap";

import * as emailjs from "emailjs-com";
import validator from "validator";
import "jquery";
import "./styleForm.css";
import "./styles.scss";

export default class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      lastName: "",
      mail: "",
      bussines: "",
      phone: "",
      rubro: "",
      motivo: "",
      message: "",
      errors: {
        name: "",
        lastName: "",
        mail: "",
        bussines: "",
        phone: "",
        rubro: "",
        motivo: "",
        message: "",
      },
    };
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value });
  }

  sentMessage(event) {
    event.preventDefault();

    if (!this.validateMail()) {
      return;
    }

    var template_params = {
      to_mail: "smartlicitaciones@ctecinnovacion.cl",
      from_name: this.state.mail,
      motive: this.state.motivo,
      Thename: this.state.name + " " + this.state.lastName,
      from_Fname: this.state.name,
      Thebusiness: this.state.bussines,
      workplace: this.state.rubro,
      message_html: this.state.message,
      phone: this.state.phone,
    };
    // var service_id = "default_service";
    // var template_id = "template_Lio0e16T";
    // var user_id = 'user_HRfMb4or8c8GfwzfM4nIh';

    emailjs
      .send(
        "default_service",
        "template_Lio0e16T",
        template_params,
        "user_HRfMb4or8c8GfwzfM4nIh"
      )
      .then(
        function (response) {
          toastr.success("Mensaje enviado con exito");
          console.log("EXITOOO!", response.status, response.text);
        },
        function (err) {
          toastr.error(err);
          console.log(err);
        }
      );

    this.setState({
      name: "",
      lastName: "",
      mail: "",
      bussines: "",
      phone: "",
      rubro: "",
      motivo: "",
      message: " ",
    });
    return <Redirect to="/contacto" />;
  }

  validateMail() {
    let errors = {};
    let formIsValid = true;
    let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w)*(\.\w{2,3})+$/;

    if (!this.state.name || this.state.name.length < 3) {
      errors.name = "3 caracteres como minimo";
      formIsValid = false;
    }

    if (!this.state.lastName || this.state.lastName.length < 3) {
      errors.lastName = "3 caracteres como minimo";
      formIsValid = false;
    }

    if (
      validator.isMobilePhone(this.state.phone) === false ||
      this.state.phone.length < 8
    ) {
      errors.message = "8 digitos como minimo";
      formIsValid = false;
    }

    if (!this.state.bussines || this.state.bussines.length < 3) {
      errors.bussines = "10 caracteres como minimo";
      formIsValid = false;
    }

    if (!this.state.mail || this.state.mail.length < 10) {
      errors.mail = "10 caracteres como minimo";
      formIsValid = false;
    }

    if (!pattern.test(this.state.mail)) {
      errors.mail = "No es un mail valido";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  /*
  dialCall = () => {

    let phoneNumber = '';

    if (Platform.OS === 'android') {
      phoneNumber = 'tel:${+56229780749}';
    } else if (Platform.OS === 'IOS') {
      phoneNumber = 'telprompt:${+56229780749}';
    }

    Linking.openURL(phoneNumber);
  };
  */

  render() {
    console.log(this.state);
    return (
      <section style={{ padding: "0" }}>
        <main className="contact">
          <div className="map-container">
            <h1>ENCUÉNTRANOS</h1>
            <ContactMap />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-4 order-md-first">
                <p className="txt-parque">
                  Nuestras oficinas están ubicadas en
                </p>
                <p className="mb-3">Plaza Ercilla 883, Santiago</p>

                <p className="txt-parque">
                  El Parque de Innovación CTeC está ubicado en
                </p>
                <p>Loteo Lo Aguirre N°18.501, Reserva Cora 6, Pudahuel</p>
                <p className="mb-3">Ruta 68, Santiago a Valparaíso</p>

                <hr></hr>
                <p>
                  <a href="mailto:contacto@ctecinnovacion.cl">
                    contacto@ctecinnovacion.cl
                  </a>
                </p>
                <p>
                  <a href="mailto:smartlicitaciones@ctecinnovacion.cl">
                    smartlicitaciones@ctecinnovacion.cl
                  </a>
                </p>
                <hr></hr>
              </div>

              <div class="col-md-8 order-last">
                <div role="form" dir="ltr" lang="en-US">
                  <div className="contacto-form">
                    <form class="form-group" onSubmit={this.handleSubmit}>
                      <div class="row my-2">
                        <div class="col-md-6">
                          <input
                            type="text"
                            name="name"
                            class="form-control pr-0"
                            value={this.state.name}
                            onChange={this.handleInputChange.bind(this)}
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            error={this.state.errors.name}
                            placeholder="NOMBRE*"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="text"
                            name="lastName"
                            class="form-control"
                            value={this.state.lastName}
                            onChange={this.handleInputChange.bind(this)}
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            error={this.state.errors.lastName}
                            placeholder="APELLIDOS*"
                          />
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-md-6">
                          <input
                            type="text"
                            name="mail"
                            class="form-control"
                            value={this.state.mail}
                            onChange={this.handleInputChange.bind(this)}
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            error={this.state.errors.mail}
                            placeholder="MAIL*"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="text"
                            name="bussines"
                            class="form-control"
                            value={this.state.bussines}
                            onChange={this.handleInputChange.bind(this)}
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            error={this.state.errors.bussines}
                            placeholder="EMPRESA*"
                          />
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-md-6">
                          <input
                            type="text"
                            name="phone"
                            class="form-control"
                            value={this.state.phone}
                            onChange={this.handleInputChange.bind(this)}
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            error={this.state.errors.phone}
                            placeholder="TELÉFONO*"
                          />
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-md-12 mt-2">
                          <select
                            name="rubro"
                            class="form-control"
                            value={this.state.rubro}
                            onChange={this.handleInputChange.bind(this)}
                            aria-invalid="false"
                            error={this.state.errors.rubro}
                          >
                            <option
                              class="option-text"
                              value="SELECCIONE RUBRO"
                            >
                              RUBRO
                            </option>
                            <option class="option-text" value="Minería">
                              Minería
                            </option>
                            <option class="option-text" value="Energía">
                              Energía
                            </option>
                            <option class="option-text" value="Sector Público">
                              Sector Público
                            </option>
                            <option class="option-text" value="Constructora">
                              Constructora
                            </option>
                            <option class="option-text" value="Inmobiliaria">
                              Inmobiliaria
                            </option>
                            <option class="option-text" value="Ingeniería">
                              Ingeniería
                            </option>
                            <option
                              class="option-text"
                              value="Proveedor de Materiales"
                            >
                              Proveedor de Materiales
                            </option>
                            <option
                              class="option-text"
                              value="Universidad y/o Entidades de Educación"
                            >
                              Universidad y/o Entidades de Educación
                            </option>
                            <option class="option-text" value="Consultora">
                              Consultora
                            </option>
                            <option
                              class="option-text"
                              value="Oficina de Arquitectura"
                            >
                              Oficina de Arquitectura
                            </option>
                            <option class="option-text" value="Industria">
                              Industria
                            </option>
                            <option
                              class="option-text"
                              value="Asociación Gremial"
                            >
                              Asociación Gremial
                            </option>
                            <option class="option-text" value="Otros">
                              Otros
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-md-12">
                          <select
                            name="motivo"
                            class="form-control"
                            value={this.state.motivo}
                            onChange={this.handleInputChange.bind(this)}
                            aria-invalid="false"
                            error={this.state.errors.motivo}
                          >
                            <option class="option-text" value="MOTIVO">
                              MOTIVO
                            </option>
                            <option class="option-text" value="Formar parte">
                              Formar parte
                            </option>
                            <option class="option-text" value="Felicitaciones">
                              Felicitaciones
                            </option>
                            <option
                              class="option-text"
                              value="Sugerencias u observaciones"
                            >
                              Sugerencias u observaciones
                            </option>
                            <option class="option-text" value="Otros">
                              Otros
                            </option>
                          </select>
                        </div>
                        <div class="col-md-12" style={{ margin: "16px 8px" }}>
                          <textarea
                            name="message"
                            cols="40"
                            rows="5"
                            class="form-control mt-2"
                            aria-invalid="false"
                            error={this.state.errors.message}
                            onChange={this.handleInputChange.bind(this)}
                            placeholder="MENSAJE"
                          ></textarea>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div
                          class="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <input
                            type="submit"
                            value="ENVIAR"
                            class="btn-submit"
                            onClick={this.sentMessage.bind(this)}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    );
  }
}
